import React from "react";
import "./style.css";
import { useColors, Fonts } from "@common";

export default ({ data, index }) => {
  const Colors = useColors();

  return (
    <div
      className="what_we_do_best_boxies"
      style={{ marginLeft: index > 0 && "32px" }}
    >
      <div className="what_we_do_best_image_box">
        <img className="what_we_do_best_img" src={data.pic} alt={"image"} />
      </div>
      <div className="what_we_do_best_boxies_texts">
        <h2
          className="what_we_do_best_title"
          style={{ color: Colors.secondary }}
        >
          {data.title}
        </h2>
        <p className="what_we_do_best_desc" style={{ color: Colors.primary }}>
          {data.desc}
        </p>
      </div>
    </div>
  );
};
