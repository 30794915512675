import React, { useRef, useState, useEffect } from "react";
import { useColors, Fonts } from "@common";
import { NavBar } from "@components";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import Politiques from "@images/backgrounds/NeofastShapes.png";
import polygonsBack from "@images/backgrounds/grayPolygons.png";
import "./index.css";
import { BiSolidRightArrow, BiSolidLeftArrow } from "react-icons/bi";

const { innerWidth, innerHeight } = window;
export const Items = ({ desc, strong, Ref, useRed }) => {
  const Colors = useColors();

  return (
    <div className="term_section__item">
      <p
        className="term_section__item_desc"
        style={{
          fontSize: "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          className="term_section_Strongs"
          style={{
            fontSize: innerWidth > 414 ? "16px" : "10px",
            fontFamily: Fonts().primarySemiBold,
            color: useRed ? Colors.error : Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {!!strong && !!desc && " : "}
        {desc}
      </p>
    </div>
  );
};

export default ({}) => {
  const Colors = useColors();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [showNextPage, setShowNextPage] = useState(false);

  const handleNextPage = () => {
    setShowNextPage((prv) => !prv);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleOnClickContact = () => {
    navigate(`/${"contacts"}`);
  };

  const handleOnClickSecteur = () => {
    navigate(`/${"secteur"}`);
  };
  const handleOnClickBlog = () => {
    navigate(`/${"blog"}`);
  };

  const handleClickPropos = () => {
    navigate(`/${"about"}`);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />

      <div
        className="term_of_use "
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <img
          src={Politiques}
          style={{ position: "absolute", width: 320, top: "5%", right: "5%" }}
        />
        {!showNextPage && (
          <div className="term_of_us_global second_use">
            <h1
              className="big_title second_use"
              style={{
                fontFamily: Fonts().primaryBold,
                color: Colors.primary,
                marginTop: "8rem",
              }}
            >
              Politique de confidentialités
            </h1>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Politique relative aux données à caractère personnel
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pour satisfaire à ses besoins, NEOFAST met en œuvre et exploite
                des traitements de données à caractère personnel. La politique a
                pour objet de satisfaire à l’obligation <br /> d’information
                d'NEOFAST et de formaliser les droits et les obligations de ses
                clients et contacts concernant le traitement de leurs données à
                caractère personnel. <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Rappel
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le Règlement (EU) 2016/679 du Parlement Européen et du Conseil
                du 27 avril 2016 relatif à la protection des personnes physiques
                à l’égard du traitement de données à caractère personnel et à la
                libre circulation de ces données, autrement appelé le Règlement
                général sur la protection des données (ci-après le " RGPD ")
                fixe le cadre juridique applicable aux traitements de données à
                caractère personnel.
                <br /> Le RGPD renforce les droits et les obligations des
                responsables de traitements, des sous-traitants, des personnes
                concernées et des destinataires des données.
                <br /> Dans le cadre de son activité, NEOFAST met en œuvre un
                traitement de données à caractère personnel regroupant les
                données de ses clients et contacts.
                <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Définitions
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pour une bonne compréhension de la présente politique il est
                précisé que :
                <br />
              </p>
              <div
                className="neo-fast-items"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ marginTop: 8 }}>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}
                  “client " désigne toute personne physique ou morale cliente du
                  réseau ;{" "}
                </span>
                <span style={{ marginTop: 8 }}>
                  {" "}
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />{" "}
                  contact” désigne toute personne physique ou morale en relation
                  avec le réseau mais qui n’est pas client (prospects,
                  relations, partenaires, …) ;
                </span>
                <span style={{ marginTop: 8 }}>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}“destinataires” désigne les personnes physiques ou
                  morales qui reçoivent communication des données à caractère
                  personnel. Les destinataires des données peuvent donc <br />{" "}
                  être aussi bien des destinataires internes que des organismes
                  extérieurs (prestataires de services supports, administration,
                  etc.).
                </span>
                <span style={{ marginTop: 8 }}>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}“responsable du traitement” : désigne la personne
                  physique ou morale qui détermine les finalités et les moyens
                  d’un traitement de données à caractère personnel.
                  <br /> Au titre de la présente politique, le responsable du
                  traitement est le réseau ;
                </span>
                <span style={{ marginTop: 8 }}>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}“sous-traitant” : désigne toute personne physique ou
                  morale qui traite des données à caractère personnel pour le
                  compte du responsable du traitement.
                  <br /> Il s’agit donc en pratique des prestataires avec
                  lesquels le réseau travaille et qui interviennent sur les
                  données à caractère personnel qu’il traite ;
                </span>
                <span style={{ marginTop: 8 }}>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}"personnes concernées" : désigne les personnes qui
                  peuvent être identifiées, directement ou indirectement. Elles
                  sont, au titre des présentes, qualifiées de “client” ou de
                  “contact”.
                  <br /> Le RGPD, en son article 12, impose que les personnes
                  concernées soient informées de leurs droits de manière
                  concise, transparente, compréhensible et aisément accessible.
                </span>
              </div>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Objet
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pour satisfaire à ses besoins, le réseau met en œuvre et
                exploite des traitements de données à caractère personnel
                relatifs à ses clients et contacts.
                <br /> La présente politique a pour objet de satisfaire à
                l’obligation d’information du réseau et ainsi de formaliser les
                droits et les obligations de ses clients et contacts au regard
                du <br /> traitement de leurs données à caractère personnel.
                <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Portée
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                La présente politique de protection des données à caractère
                personnel a vocation à s’appliquer dans le cadre de la mise en
                place du traitement des données à caractère personnel des
                clients et contacts d'NEOFAST.
                <br /> La présente politique ne porte que sur les traitements
                dont le réseau est responsable ainsi que sur les données
                qualifiées de “structurées”. <br /> Le traitement de données à
                caractère personnel peut être géré directement par NEOFAST ou
                par le biais d’un sous-traitant spécifiquement désigné par lui.
                <br /> Cette politique est indépendante de tout autre document
                pouvant s’appliquer au sein de la relation contractuelle entre
                le réseau et ses clients et contacts, notamment nos conditions
                générales d’intervention ou notre politique de cookies.
                <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Principes généraux et engagement
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Aucun traitement n’est mis en œuvre par NEOFAST concernant des
                données de clients et contacts s’il ne porte pas sur des données
                à caractère personnel collectées par ou pour nos services ou
                traitées en relation avec nos services et s’il ne répond pas aux
                principes généraux du RGPD. <br /> Tout nouveau traitement,
                modification ou suppression d’un traitement existant sera porté
                à la connaissance des clients et contacts par le biais d’une
                modification de la présente politique.
                <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Types de données collectées
              </h1>
              <h3
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.primary,
                }}
              >
                Données non techniques selon les cas d’usage :
              </h3>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                - Identification : nom, prénom, civilité, fonction, pseudonyme,
                pseudo réseaux sociaux
                <br /> - Coordonnées : Téléphone, adresse e-mail, adresse
                postale, fax, … <br />- Photo lorsque vous nous accordez ce
                droit (dans le cadre d’un webinaire, d’une vidéo d’information,
                etc.)
                <br /> - Vie professionnelle - Données bancaires si nécessaires
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h3
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.primary,
                }}
              >
                Données techniques selon les cas d’usage :
              </h3>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                - Données d’identification (adresse IP)
                <br /> - Données de connexion (logs notamment)
                <br /> - Données relatives au consentement essentiellement pour
                les abonnements en ligne
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h3
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.primary,
                }}
              >
                Données sensibles:
              </h3>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                - Le réseau ne traite pas de données sensibles au sens de
                l’article 9 du RGPD.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h3
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.primary,
                }}
              >
                Origines des données:
              </h3>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le réseau collecte les données de ses clients et de ses contacts
                à partir de :<br />- données fournies par le client dans le
                cadre d’un dossier confié au réseau ; <br /> - cartes de visite
                ;<br /> - fiches ou formulaires électroniques remplis par le
                client ; <br /> - inscription ou abonnement à nos services en
                ligne ;<br /> - inscription à des évènements organisés par le
                réseau ;<br /> - listes communiquées par les organisateurs
                d’évènements ;<br /> - échanges via courrier électronique ou via
                les réseaux sociaux.
                <br />
                De manière exceptionnelle nous pouvons louer des bases de
                données.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h3
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.primary,
                }}
              >
                Finalités du traitement:
              </h3>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Selon les cas, NEOFAST traite vos données pour les finalités
                suivantes : <br />- traitement des dossiers confiés au réseau ;
                <br /> - gestion de la relation client ;<br /> - gestion des
                événements organisés par le réseau ;<br /> - envoi de nos
                newsletters ou fils d’informations ;<br /> - réponses aux
                questions qui nous sont posées (par téléphone ou en ligne) ;
                <br /> - réponses à des appels d’offres publics ou privés ;
                <br /> - envoi de vœux et autres félicitations de la part du
                réseau ;<br /> - amélioration de nos services ;<br /> - réponses
                à nos obligations administratives ;<br /> - réalisation de
                statistiques.
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Base légale
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Les finalités de traitement présentées ci-dessus reposent sur
                les conditions de licéité suivantes :
                <br />
                <strong
                  style={{
                    fontFamily: Fonts().primaryBold,
                    color: Colors.primary,
                  }}
                >
                  {" "}
                  Clients :
                </strong>{" "}
                 Exécution précontractuelle ou contractuelle.
                <br />
                <strong
                  style={{
                    fontFamily: Fonts().primaryBold,
                    color: Colors.primary,
                  }}
                >
                  {" "}
                  Contacts : 
                </strong>{" "}
                 Intérêt légitime et lorsque la loi le requiert, consentement.
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h3
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Destinataires des données
              </h3>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le réseau s’assure que les données ne soient accessibles qu’à
                des destinataires internes ou externes autorisés.
              </p>

              <div className="term_of_us_content_1">
                <h3
                  className="term_of_us_small_title"
                  style={{
                    fontFamily: Fonts().primarySemiBold,
                    color: Colors.primary,
                  }}
                >
                  Destinataires internes :
                </h3>
                <p
                  className="term_of_us_small_desx"
                  style={{
                    fontFamily: Fonts().primaryRegular,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ marginTop: 8 }}>
                    <BiSolidRightArrow
                      style={{ color: Colors.secondary, fontSize: 12 }}
                    />
                    {"  "}Juristes
                  </span>
                  <span style={{ marginTop: 8 }}>
                    <BiSolidRightArrow
                      style={{ color: Colors.secondary, fontSize: 12 }}
                    />
                    {"  "}Consultants
                  </span>
                  <span style={{ marginTop: 8 }}>
                    <BiSolidRightArrow
                      style={{ color: Colors.secondary, fontSize: 12 }}
                    />
                    {"  "} Personnel administratif
                  </span>
                  <span style={{ marginTop: 8 }}>
                    <BiSolidRightArrow
                      style={{ color: Colors.secondary, fontSize: 12 }}
                    />
                    {"  "}Stagiaires
                  </span>
                  <span style={{ marginTop: 8 }}>
                    <BiSolidRightArrow
                      style={{ color: Colors.secondary, fontSize: 12 }}
                    />
                    {"  "}Dirigeants
                  </span>
                </p>
                <br />
                <h3
                  className="term_of_us_small_title"
                  style={{
                    fontFamily: Fonts().primarySemiBold,
                    color: Colors.primary,
                  }}
                >
                  Destinataires externes :
                </h3>
                <p
                  className="term_of_us_small_desx"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  - Prestataires ou services support (e.g., service RH,
                  prestataire informatique, etc.) <br />- Administration
                </p>
                <br />
                <h3
                  className="term_of_us_small_title"
                  style={{
                    fontFamily: Fonts().primarySemiBold,
                    color: Colors.primary,
                  }}
                >
                  Durée de conservation:
                </h3>
                <p
                  className="term_of_us_small_desx"
                  style={{ fontFamily: Fonts().primaryRegular }}
                >
                  La durée de conservation des données est définie par le réseau
                  au regard des contraintes légales et contractuelles qui pèsent
                  sur lui.
                  <br /> Elle est fixée dans le cadre de sa politique de durée
                  de conservation.
                  <br /> Passés les délais fixés dans ladite politique, les
                  données sont soit supprimées, soit conservées après avoir été
                  anonymisées, notamment pour des raisons d’usage statistique.
                  <br />
                  Il est rappelé aux clients et contacts que la suppression ou
                  l’anonymisation sont des opérations irréversibles et que le
                  réseau n’est plus, par la suite, en mesure de les restaurer.
                  <br />
                </p>
              </div>
            </div>
          </div>
        )}
        {showNextPage && (
          <div
            className="term_of_us_global second_use"
            style={{ position: "relative", top: "4rem" }}
          >
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit d’accès
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Les clients et contacts disposent traditionnellement d’un droit
                de demander au réseau la confirmation que des données les
                concernant sont ou non traitées.
                <br /> Les clients et contacts disposent également d’un droit
                d’accès conditionné au respect des règles suivantes : <br />
              </p>
              <p
                className="term_of_us_small_desx"
                style={{
                  fontFamily: Fonts().primaryRegular,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}La demande émane de la personne elle-même et est
                  accompagnée d’une copie d’un titre d’identité, à jour ;
                </span>
                <span style={{ marginTop: 8 }}>
                  <BiSolidRightArrow
                    style={{ color: Colors.secondary, fontSize: 12 }}
                  />
                  {"  "}la demande doit être formulée par écrit à l’adresse
                  suivante : contact@NEOFAST.com
                </span>
                <br />
                Les clients et contacts ont le droit de demander une copie de
                leurs données à caractère personnel faisant l’objet du
                traitement auprès du réseau. <br />
                Toutefois, en cas de demande de copie supplémentaire, le réseau
                pourra exiger la prise en charge financière de ce coût par les
                clients et contacts.
                <br /> Si les clients et contacts présentent leur demande de
                copie des données par voie électronique, les informations
                demandées lui seront fournies sous une forme électronique
                d’usage courant, sauf demande contraire.
                <br /> Les clients et contacts sont informés que ce droit
                d’accès ne peut porter sur des informations ou données
                confidentielles ou encore pour lesquelles la loi n’autorise pas
                la communication.
                <br /> Ce droit ne peut en aucun cas permettre d’accéder à des
                documents et des pièces confiées au réseau et qui sont soumises
                au secret professionnel.
                <br /> Le droit d’accès ne doit pas être exercé de manière
                abusive c’est-à-dire réalisé de manière régulière dans le seul
                but de déstabiliser le réseau.
                <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Mise à jour – Actualisation et rectification
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                L’exercice de ce droit s’exerce auprès de votre interlocuteur
                habituel, à défaut auprès de la direction du réseau.
                <br /> Afin de permettre une mise à jour régulière des données à
                caractère personnel collectées par le réseau, celui-ci pourra
                solliciter les clients et contacts qui auront la possibilité de
                satisfaire à ses demandes. Le réseau ne pourra se voir reprocher
                une absence de mise à jour si le client ou le contact
                n’actualise pas ses données.
                <br />
              </p>
            </div>
            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit à l’effacement
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le droit à l’effacement des clients et contacts ne sera pas
                applicable dans les cas où le traitement est mis en œuvre pour
                répondre à une obligation légale.
                <br /> En dehors de cette situation, les clients et contacts
                pourront demander l’effacement de leurs données dans les cas
                limitatifs suivants :<br /> - lorsque les données à caractère
                personnel ne sont plus nécessaires au regard des finalités pour
                lesquelles elles ont été collectées ou traitées d’une autre
                manière ;<br /> - lorsque la personne concernée retire le
                consentement sur lequel est fondé le traitement et qu’il
                n’existe pas d’autre fondement juridique au traitement ;<br /> -{" "}
                lorsque la personne concernée s’oppose à un traitement
                nécessaire aux fins des intérêts légitimes poursuivis par le
                réseau et qu’il n’existe pas de motif légitime impérieux pour le
                traitement ;<br /> - lorsque la personne concernée s’oppose à un
                traitement de ses données à caractère personnel à des fins de
                prospection, y compris au profilage ;<br /> - lorsque les
                données à caractère personnel ont fait l’objet d’un traitement
                illicite.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit à la limitation
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Les clients et contacts sont informés que ce droit n’a pas
                vocation à s’appliquer dans la mesure où le traitement opéré par
                le réseau est licite et que toutes les données à caractère
                personnel collectées sont nécessaires à l’exécution du contrat
                commercial. Droit
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit à la portabilité
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le réseau fait droit à la portabilité des données dans le cas
                particulier des données communiquées par les clients ou les
                contacts eux-mêmes, sur des services en ligne proposés par le
                réseau et pour les finalités reposant sur le seul consentement
                des personnes. Dans ce cas les données seront communiquées dans
                un format structuré, couramment utilisé et lisible par une
                machine.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Décision individuelle automatisée
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le réseau ne procède pas à des décisions individuelles
                automatisées.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit post mortem
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Les clients et contacts sont informés qu’ils disposent du droit
                de formuler des directives concernant la conservation,
                l’effacement et la communication de leurs données post-mortem.
                <br />
                La communication de directives spécifiques post-mortem et
                l’exercice de leurs droits s’effectuent par écrit à l’adresse
                électronique suivante : contact@NEOFAST.com
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Justification
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pour l’ensemble des droits mentionnés dont bénéficie le client
                ou le contact et conformément à la législation sur la protection
                des données à caractère personnel, vous êtes informés qu’il
                s’agit de droits de nature individuelle qui ne peuvent être
                exercés que par la personne concernée relativement à ses propres
                informations.
                <br /> Pour satisfaire à cette obligation, nous vérifierons
                l’identité de la personne concernée.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Caractère facultatif ou obligatoire des réponses
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Les clients et contacts sont informés sur chaque formulaire de
                collecte des données à caractère personnel du caractère
                obligatoire ou facultatif des réponses par la présence d’un
                astérisque.
                <br /> Dans le cas où des réponses sont obligatoires, le réseau
                explique aux clients et contacts les conséquences d’une absence
                de réponse.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit d’usage
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le réseau se voit conférer par les clients et contacts un droit
                d’usage et de traitement de leurs données à caractère personnel
                pour les finalités exposées ci-dessus. Toutefois, les données
                enrichies qui sont le fruit d’un travail de traitement et
                d’analyse du réseau, autrement appelées les données enrichies,
                demeurent sa propriété exclusive (analyse d’usage, statistiques,
                etc.).
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Sous traitance
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Le réseau informe ses clients et contacts qu’il pourra faire
                intervenir tout sous-traitant de son choix dans le cadre du
                traitement de leurs données à caractère personnel.
                <br /> Dans ce cas, le réseau s’assure du respect par le
                sous-traitant de ses obligations en vertu du RGPD.
                <br /> Le réseau s’engage à signer avec tous ses sous-traitants
                un contrat écrit et impose aux sous-traitants les mêmes
                obligations en matière de protection des données que lui.
                <br /> De plus, le réseau se réserve le droit de procéder à un
                audit auprès de ses sous-traitants afin de s’assurer du respect
                des dispositions du RGPD.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Sécurité
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Il appartient au réseau de définir et de mettre en œuvre les
                mesures techniques de sécurité, physique ou logique, qu’il
                estime appropriées pour lutter contre la destruction, la perte,
                l’altération ou la divulgation non autorisée des données de
                manière accidentelle ou illicite.
                <br />
                Parmi ces mesures figurent principalement :<br />- la sécurité
                de l’accès à nos postes informatiques et smartphones;
                <br /> - identifiants de connexion et mots de passe pour toutes
                nos applications métiers ;<br /> - la gestion des habilitations
                pour l’accès aux données ;<br /> - mot de passe complexe pour
                notre réseau Wi-Fi modifié à intervalle régulier.
                <br />
                Pour ce faire, le réseau peut se faire assister de tout tiers de
                son choix pour procéder, aux fréquences qu’il estimera
                nécessaires, à des audits de vulnérabilité ou des tests
                d’intrusion.
                <br /> En tout état de cause, le réseau s’engage, en cas de
                changement des moyens visant à assurer la sécurité et la
                confidentialité des données à caractère personnel, à les
                remplacer par des moyens d’une performance supérieure.
                <br /> Aucune évolution ne pourra conduire à une régression du
                niveau de sécurité.
                <br /> En cas de sous-traitance d’une partie ou de la totalité
                d’un traitement de données à caractère personnel, le réseau
                s’engage à imposer contractuellement à ses sous-traitants des
                garanties de sécurité par le biais de mesures techniques de
                protection de ces données et les moyens humains appropriés.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Violation des données
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                En cas de violation de données à caractère personnel, le réseau
                s’engage à le notifier à la Cnil dans les conditions prescrites
                par le RGPD.
                <br /> Si ladite violation fait porter un risque élevé pour les
                clients et contacts et que les données n’ont pas été protégées,
                le réseau :<br /> en avisera les clients et contacts concernés ;{" "}
                <br />
                communiquera aux clients et contacts concernés les informations
                et recommandations nécessaires.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Référent données personnelles
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                NEOFAST a désigné un référent “données personnelles”. <br /> Les
                coordonnées de notre référent données personnelles sont les
                suivantes :<br /> Monsieur Gilles RAUCH - contact@NEOFAST.com
                <br />
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Droit d’introduire une réclamation auprès de la CNIL
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Les clients et contacts concernés par le traitement de leurs
                données à caractère personnel sont informés de leur droit
                d’introduire une plainte auprès d’une autorité de contrôle, à
                savoir la Cnil en France, s’ils estiment que le traitement de
                données à caractère personnel les concernant n’est pas conforme
                à la réglementation européenne de protection des données, à
                l’adresse suivante :<br /> Cnil – Service des plaintes
                <br /> 3, place de Fontenoy – TSA 80715 <br /> 75334 PARIS CEDEX
                07 <br />
                Tél : 01 53 73 22 22
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Modification
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                La présente politique peut être modifiée ou aménagée à tout
                moment en cas d’évolution légale, jurisprudentielle, des
                décisions et recommandations de la Cnil ou des usages. Toute
                nouvelle version de la présente politique sera portée à la
                connaissance des clients et contacts par tout moyen choisi par
                le réseau en ce compris la voie électronique.
                <br />
              </p>
            </div>

            <div className="term_of_us_content_1">
              <h1
                className="term_of_us_small_title"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.secondary,
                }}
              >
                Pour plus d’informations
              </h1>
              <p
                className="term_of_us_small_desx"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Pour toutes informations complémentaires, vous pouvez contacter
                notre référent à l’adresse électronique suivante
                : contact@NEOFAST.com
                <br /> Pour toute autre information plus générale sur la
                protection des données personnelles, vous pouvez consulter le
                site de la CNIL.
                <br />
              </p>
            </div>
          </div>
        )}
        <div
          style={{
            width: "calc(100% - 10%)",
            display: "flex",
            flexDirection: showNextPage ? "row-reverse" : "row",
            alignItems: "center",
            justifyContent: showNextPage ? "flex-start" : "flex-end",
            cursor: "pointer",
            margin: "2rem auto",
            position: "relative",
            top: "4rem",
          }}
          onClick={handleNextPage}
        >
          <h3
            style={{
              color: Colors.secondary,
              fontFamily: Fonts().primarySemiBold,
              fontSize: 16,
              marginTop: 16,
            }}
          >
            {showNextPage ? "Page précédente" : "Page suivant"}
          </h3>{" "}
          {!showNextPage && (
            <BiSolidRightArrow
              style={{ color: Colors.secondary, fontSize: 18, marginLeft: 8 }}
            />
          )}
          {showNextPage && (
            <BiSolidLeftArrow
              style={{ color: Colors.secondary, fontSize: 18, marginRight: 8 }}
            />
          )}
        </div>
        <img
          src={polygonsBack}
          style={{
            position: "absolute",
            left: "0%",
            height: "500px",
            width: "100%",
            top: "77%",
            zIndex: -1,
          }}
        />
      </div>

      <Footer
        isIn
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />
    </div>
  );
};
