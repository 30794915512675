import { URL } from "@common";
const axios = require("axios").default;

export const backend = axios.create({
  baseURL: URL, //dev local
  headers: { "Content-Type": "application/json" },
});

export const CONTACTS2 = {
  NEW: async (data) => {
    return await backend.post("/contacts/new", data);
  },
};