import React from "react";
import { URL } from "@common";
// import { store } from "../../redux/store";
import { LOGOUT } from "@redux/account/types";
import jwt_decode from "jwt-decode";
import { store } from "../redux/store";
import { toast } from "react-toastify";
const axios = require("axios").default;

export const backend = axios.create({
  baseURL: URL, //dev local
  headers: { "Content-Type": "application/json" },
});

backend.interceptors.request.use(
  (config) => {
    const access_token = store?.getState()?.account?.user?.token;
    if (!!access_token) {
      const { exp } = jwt_decode(access_token);
      const DateExp = new Date(exp * 1000);
      if (DateExp <= new Date()) {
        store.dispatch({ type: LOGOUT });
        toast.error(
          "Votre compte a expiré, veuillez réessayer de vous connecter"
        );
        return config;
      } else {
        config.headers.Authorization = `Bearer ${access_token}`;
        config.headers["Content-Type"] = "application/json";
        return config;
      }
    }
  },
  (error) => {
    if (error) {
      store.dispatch({ type: LOGOUT });
      toast.error(
        "Votre compte a expiré, veuillez réessayer de vous connecter"
      );
      //   navigate("/");
    }
  }
);
