import React, { useEffect } from "react";
import { useRoutes, BrowserRouter as Router } from "react-router-dom";
import {
  Home,
  Politiques,
  Privacy,
  NosSuject,
  NewsletterRegistrationForm,
  SignUp,
  SignIn,
  Dashbord,
  BlogsManagement,
  Practice
} from "@screens";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },

    { path: "/:name", element: <Home /> },
    { path: "/test", element: <Practice /> },
    {
      path: "/newsletter_registration_form",
      element: <NewsletterRegistrationForm />,
    },
    { path: "/nos_sujets_d'études", element: <NosSuject /> },
    { path: "/politiques", element: <Politiques /> },
    { path: "/politique_de_confidentialites", element: <Privacy /> },
    { path: "/back_ofice/neofast/auth/sign_up", element: <SignUp /> },
    { path: "/back_ofice/neofast/auth/sign_in", element: <SignIn /> },
    {
      path: "/back_ofice/neofast/administration/BlogsManagement",
      element: <BlogsManagement />,
    },
    {
      path: "/back_ofice/neofast/administration/data/:date",
      element: <Dashbord />,
    },
    { path: "*", element: <Home /> },
  ]);
  return routes;
};

export default ({}) => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};
