import React, { useState } from "react";
import { NavBar, TextInput, Select } from "@components";
import { Fonts, useColors } from "@common";
import Footer from "../Footer";
import "./index.css";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NOTSECUREBLOGS } from "@services";
import plexusShapesFloatingParticles from "@images/backgrounds/plexusShapesFloatingParticles.png";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const GENDER = [
  { id: 1, name: "Homme" },
  { id: 2, name: "Femme" },
];

export default ({}) => {
  const navigate = useNavigate();
  const Colors = useColors();
  const [seciete, setSociete] = useState("");
  const [functionNeo, setFunctionNeo] = useState("");
  const [gender, setGender] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [proEmail, setProEmail] = useState("");
  const [confirmationEmail, setConfirmationEmail] = useState("");
  const [adresse, setAdresse] = useState("");
  const [completedAdresse, setCompletedAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [tele, setTele] = useState("");
  const [portable, setPortable] = useState("");
  const [accepted, setAccepted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ field: "", message: "" });

  const handleSociety = (e) => {
    setSociete(e.target.value);
  };
  const handleFunction = (e) => {
    setFunctionNeo(e.target.value);
  };
  const handlegender = (e) => {
    setGender(e.target.value);
  };
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleProEmail = (e) => {
    setProEmail(e.target.value);
  };
  const handleConfirmedEmail = (e) => {
    setConfirmationEmail(e.target.value);
  };
  const handleAdresse = (e) => {
    setAdresse(e.target.value);
  };
  const handleCompletedAdresse = (e) => {
    setCompletedAdresse(e.target.value);
  };
  const handleCodePostal = (e) => {
    setCodePostal(e.target.value);
  };
  const handleVille = (e) => {
    setVille(e.target.value);
  };
  const handleTele = (e) => {
    setTele(e.target.value);
  };
  const handlePortable = (e) => {
    setPortable(e.target.value);
  };

  const handleAccepted = (e) => {
    setAccepted(e.target.checked);
  };

  // const handleChange = () => {
  //   setIsLoaing(true);

  // };

  const handleOnClickAccepte = () => {
    setIsLoading(false);
    if (
      !seciete &&
      !firstname &&
      !lastName &&
      !proEmail &&
      !confirmationEmail &&
      !tele
    ) {
      toast.error("Vous devez d'abord remplir les champs suivants", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsLoading(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (!seciete) {
      setError({ field: "societe", message: "Champ obligatoire" });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (!lastName) {
      setError({ field: "lastname", message: "Champ obligatoire" });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (!firstname) {
      setError({ field: "firstname", message: "Champ obligatoire" });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (!proEmail) {
      setError({ field: "proemail", message: "Champ obligatoire" });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (!confirmationEmail) {
      setError({ field: "confemail", message: "Champ obligatoire" });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    // Email validation regular expression
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!proEmail || !emailRegex.test(proEmail)) {
      setError({ field: "proemail", message: "Email invalide" });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (!confirmationEmail || !emailRegex.test(confirmationEmail)) {
      setError({
        field: "confemail",
        message: "Email de confirmation invalide",
      });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (proEmail !== confirmationEmail) {
      toast.error("Email et Email de confirmation ne sont pas les mêmes", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setError({
        field: "confemail",
        message: "Email confirmé n'est pas la même chose avec email",
      });
      return;
    } else {
      setError({ field: "", message: "" });
    }

    if (!tele) {
      setError({ field: "tele", message: "Champ obligatoire" });
      return;
    } else {
      setError({ field: "", message: "" });
      if (
        seciete &&
        lastName &&
        firstname &&
        proEmail &&
        confirmationEmail &&
        tele
      ) {
        const params = {
          company: seciete,
          function: functionNeo,
          // gender: gender,
          firstname: firstname,
          lastname: lastName,
          email: proEmail,
          // adress: adresse,
          // completed_adress: completedAdresse,
          // code_postal: codePostal,
          // city: ville,
          phone: tele,
          // portable: portable,
          accepted: accepted,
        };
        NOTSECUREBLOGS.NEW(params)
          .then((data) => {
            setIsLoading(false);
            if (data.status === 201 || data.status === 200) {
              setProEmail("");
              setConfirmationEmail("");
              setTele("");
              setSociete("");
              setFirstName("");
              setCodePostal("");
              setSociete("");
              setLastName("");
              setFunctionNeo("");
              setGender("");
              setCompletedAdresse("");
              setAccepted("");
              setVille("");
              setPortable("");

              toast.success("Les informations personnelles enregistrées", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error) {
              setTimeout(() => {
                // setProEmail("");
                // setConfirmationEmail("");
                // setTele("");
                // setSociete("");
                // setCodePostal("");
                // setSociete("");
                // setLastName("");
                // setFirstName("");
                // setFunctionNeo("");
                // setGender("");
                // setCompletedAdresse("");
                // setAccepted("");
                // setVille("");
                // setPortable("");
                toast.error("Quelque chose s'est mal passé", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }, 100);
            }
          });
      } else {
        setIsLoading(false);
        setTimeout(() => {
          setProEmail("");
          setConfirmationEmail("");
          setTele("");
          setSociete("");
          setCodePostal("");
          setSociete("");
          setLastName("");
          setFunctionNeo("");
          setGender("");
          setCompletedAdresse("");
          setAccepted("");
          setVille("");
          setPortable("");
          toast.error("Vous devez d'abord remplir les champs", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }, 100);
      }
    }
  };

  const handleOnChange = (e) => {
    setGender(e.target.value);
  };

  const handleOnClickContact = () => {
    navigate(`/${"contacts"}`);
  };

  const handleOnClickSecteur = () => {
    navigate(`/${"secteur"}`);
  };
  const handleOnClickBlog = () => {
    navigate(`/${"blog"}`);
  };

  const handleClickPropos = () => {
    navigate(`/${"about"}`);
  };

  return (
    <div className="newsletter_registration_form" style={{backgroundImage: `url(${plexusShapesFloatingParticles})`}}>
      <NavBar
        isActive={5}
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />
      <div className="newsletter_registration_form_content">
        <div className="newsletter_registration_form_header">
          <h1
            className="newsletter_registration_form_object"
            style={{
              color: Colors.primary,
              fontFamily: Fonts().primaryBold,
              fontSize: 26,
            }}
          >
            Formulaire d’inscription à la newsletter
          </h1>
          <h2
            className="newsletter_registration_form_title"
            style={{
              color: Colors.secondary,
              fontFamily: Fonts().primarySemiBold,
              fontSize: 18,
              marginTop: 45,
              marginBottom: 45,
            }}
          >
            Inscription aux actualités
          </h2>
        </div>
        <div className="first_tow_fields">
          <TextInput
            value={seciete}
            placeholder="Société"
            required
            onChange={handleSociety}
            error={!!error && error.field === "societe" ? error.message : null}
          />
          <TextInput
            value={functionNeo}
            placeholder="Fonction"
            // required
            onChange={handleFunction}
            error={!!error && error.field === "function" ? error.message : null}
          />
        </div>
        {/* <div className="select_field">
          <Select
            Label={"Genre"}
            width="200px"
            data={GENDER}
            value={gender}
            handleOnChange={handleOnChange}
          />
        </div> */}
        <div className="____fields">
          <TextInput
            value={lastName}
            placeholder="Nom"
            required
            onChange={handleLastName}
            error={!!error && error.field === "lastname" ? error.message : null}
          />
          <TextInput
            value={firstname}
            placeholder="Prénom"
            required
            onChange={handleFirstName}
            error={
              !!error && error.field === "firstname" ? error.message : null
            }
          />
          <TextInput
            value={proEmail}
            placeholder="Email Professionnel"
            required
            onChange={handleProEmail}
            error={!!error && error.field === "proemail" ? error.message : null}
          />
          {/* <TextInput
            value={confirmationEmail}
            placeholder="Confirmation Email"
            required
            onChange={handleConfirmedEmail}
            error={
              !!error && error.field === "confemail" ? error.message : null
            }
          /> */}
          {/* <TextInput
            value={adresse}
            placeholder="Adresse"
            onChange={handleAdresse}
          />
          <TextInput
            value={completedAdresse}
            placeholder="Adresse complément"
            onChange={handleCompletedAdresse}
          />
          <TextInput
            value={codePostal}
            placeholder="Code postal"
            onChange={handleCodePostal}
          /> */}
          {/* <TextInput value={ville} placeholder="Ville" onChange={handleVille} /> */}
          {/* <TextInput
            value={tele}
            placeholder="Téléphone"
            required
            onChange={handleTele}
            error={!!error && error.field === "tele" ? error.message : null}
          /> */}
          {/* <TextInput
            value={portable}
            placeholder="Portable"
            onChange={handlePortable}
          /> */}
        </div>
        <div className="____fields">
          <TextInput
            value={confirmationEmail}
            placeholder="Confirmation Email"
            required
            onChange={handleConfirmedEmail}
            error={
              !!error && error.field === "confemail" ? error.message : null
            }
          />
          <TextInput
            value={tele}
            placeholder="Téléphone"
            required
            onChange={handleTele}
            error={!!error && error.field === "tele" ? error.message : null}
          />
        </div>
        <div className="acceptation__form_newslater">
          <p
            className="text_desc"
            style={{
              fontFamily: Fonts().primaryRegular,
              color: Colors.primary,
              fontSize: 14,
            }}
          >
            Il est essentiel de prendre note que les réponses aux cases marquées
            d'un astérisque sont requises et indispensables afin que nous
            puissions vous envoyer cette newsletter. Sans ces réponses, il nous
            serait impossible de vous l'adresser.
          </p>
          <br />
          <div className="the_check_box">
            <Checkbox
              {...label}
              checked={accepted}
              onChange={handleAccepted}
              style={{ color: Colors.secondary }}
            />
            <p
              className="text_desc_check"
              style={{
                fontFamily: Fonts().primaryRegular,
                color: Colors.primary,
                fontSize: 14,
              }}
            >
              en soumettant ce formulaire, j'accepte que les informations
              saisies soient utilisées pour l'envoi d'actualités.
            </p>
          </div>
          <br />
          <p
            className="text_desc"
            style={{
              fontFamily: Fonts().primaryRegular,
              color: Colors.primary,
              fontSize: 14,
            }}
          >
            Vous pourrez facilement vous désinscrire à tout moment via les liens
            de désinscription présents dans chacun de nos emails.
          </p>
        </div>
        <div className="this_is_the_actions">
          <div
            onClick={handleOnClickAccepte}
            className="skewed-link-container"
            style={{
              borderBottom: "1px solid",
              border: `1px solid ${Colors.secondary}`,
              width: "200px",
              textAlign: "center",
              padding: "14px 0px",
              cursor: "pointer",
              background: Colors.secondary,
              position: "relative",
            }}
          >
            <span
              className="skewed-link-text"
              style={{
                color: "#FFF",
                fontFamily: Fonts().primarySemiBold,
                fontSize: 14,
              }}
            >
              Validé
            </span>
            {isLoading && (
              <Box
                sx={{ display: "flex" }}
                style={{
                  fontSize: 100,
                  color: Colors.primary,
                  position: "absolute",
                  right: 20,
                  top: "14px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </div>
      </div>
      <Footer
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />
    </div>
  );
};
