import React from "react";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./button.css";

export default ({
  Label,
  style,
  useIcon,
  IconName,
  notImage,
  Click,
  isLoading,
}) => {
  return (
    <Button variant="text" style={style} onClick={Click} disabled = {isLoading}>
      {Label}
      {useIcon && !notImage && (
        <img src={IconName} style={{ width: 10, height: 20, marginLeft: 15 }} />
      )}
      {useIcon && notImage && !isLoading && (
        <IconName
          style={{
            fontSize: "16px",
            marginLeft: "15px",
            marginTop: "2px",
            fontWeight: "bold",
          }}
        />
      )}
      {isLoading && (
        <Box sx={{ display: "flex" }}>
          {" "}
          <CircularProgress />
        </Box>
      )}
    </Button>
  );
};
