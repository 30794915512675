import React from "react";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  searchInputContainer: {
    display: "flex",
    width: "250px",
    background: "#FFF",
    alignItems: "center",
    border: `1px solid #e7e7e7`,
    borderRadius: 4,
    padding: "8px 12px",
    transition: "border-color 0.3s ease-in-out",
    "&:hover": {
      borderColor: "#FC7032",
    },
  },
  searchInput: {
    border: "none",
    outline: "none",
    flex: 1,
    marginLeft: 8,
    fontSize: "inherit",
  },
}));

export default ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.searchInputContainer}>
      <SearchIcon style={{color: "#FC7032"}} />
      <input type="text" placeholder="" className={classes.searchInput} />
    </div>
  );
};
