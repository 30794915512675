import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "../../../redux/account/types";
import jwt_decode from "jwt-decode";
import "./index.css";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Menu from "./Menu";
import { Badge } from "@mui/material";
import { PendingIcon } from "@components";
import { Fonts } from "@common";
import { SUBSCRIBED, CONTACTS } from "@services";

export default ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLogged } = useSelector(({ account }) => account);
  const [contacts, setContacts] = useState([]);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const displayOnce = useRef(true);

  useEffect(() => {
    if (!isLogged) {
      navigate("/back_ofice/neofast/auth/sign_in");
    }
    if (!!user) {
      if (!!user?.token) {
        const { exp } = jwt_decode(user?.token);
        const DateExp = !!user ? new Date(exp * 1000) : null;
        if (DateExp <= new Date()) {
          dispatch({ type: LOGOUT });
          navigate("/back_ofice/neofast/auth/sign_in");
        }
      }
    }
  }, [user, isLogged]);

  useEffect(() => {
    if (displayOnce.current) {
      fetchContacts();
      fetchSub();
      displayOnce.current = false;
    }
  }, []);

  const fetchContacts = () => {
    CONTACTS.ALL()
      .then((result) => {
        if (result) {
          const fromHome = [];
          const fromNotHome = [];
          setContacts(result.data.data);
          result.data.data.map((ele) => {
            if (ele.from === "home") {
              fromHome.push(ele);
            } else {
              fromNotHome.push(ele);
            }
          });
          setFirst([...fromHome, ...fromNotHome]);
          setSecond(fromNotHome);
          console.log(fromHome, fromNotHome);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error, "@************@ from fetch data");
        }
      });
  };

  const fetchSub = () => {
    SUBSCRIBED.ALL()
      .then((result) => {
        if (result) {
          setContacts(result.data.data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error, "@************@ from fetch data");
        }
      });
  };

  const HomeUsersTable = ({ data }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Society</TableCell>
              <TableCell>Sujet du message</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Secteur activité </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.email}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.society}</TableCell>
                <TableCell>{user.subject}</TableCell>
                <TableCell>{user.message}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.secteur}</TableCell>
                {/* <TableCell><span className="from__dashboard">{user.from === "home" ? "Accueil" : ""} </span></TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const ComingSoonUsersTable = ({ data }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Entreprise</TableCell>
              <TableCell>Fonction</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Adresse </TableCell>
              <TableCell>Adresse complète </TableCell>
              <TableCell>Code postale </TableCell>
              <TableCell>Ville </TableCell>
              <TableCell>Téléphone </TableCell>
              <TableCell>Portable </TableCell>
              <TableCell>Afficher les actualités acceptées </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.company}</TableCell>
                <TableCell>{user.function}</TableCell>
                <TableCell>{user.gender}</TableCell>
                <TableCell>{user.lastname}</TableCell>
                <TableCell>{user.firstname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.adress} </TableCell>
                <TableCell> {user.completed_adress} </TableCell>
                <TableCell>{user.code_postal} </TableCell>
                <TableCell>{user.city} </TableCell>
                <TableCell>{user.phone} </TableCell>
                <TableCell>{user.portable} </TableCell>
                <TableCell>{user.accepted ? "OUI" : "NON"} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="container_backend">
        <Menu />
        <div
          className="content_backofice"
          style={{ width: "92%", margin: "100px auto" }}
        >
          <Badge
            badgeContent={<PendingIcon />}
            // color="info"
            // sx={{ backgroundColor: "#e38e16" }}
          >
            <div style={{ padding: 5, fontFamily: Fonts().primaryBold }}>
              Liste des contacts
            </div>
          </Badge>
          <HomeUsersTable data={first} />
          <br />
          <br />
          <Badge
            badgeContent={<PendingIcon />}
            // color="info"
            // sx={{ backgroundColor: "#e38e16" }}
          >
            <div style={{ padding: 5, fontFamily: Fonts().primaryBold }}>
              Utilisateurs inscrits à la newsletter
            </div>
          </Badge>
          <ComingSoonUsersTable data={contacts} />
        </div>
      </div>
    </div>
  );
};
