import React, { useEffect, useRef, useState } from "react";
import Router from "./Routes";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToTopButton } from "@components";

function App() {
  const [isScrollGreaterThan20, setIsScrollGreaterThan20] = useState(false);

  const handleScrollToTheTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isGreaterThan20 = scrollTop > 20;
      setIsScrollGreaterThan20(isGreaterThan20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Router />
          <ToastContainer />
          {isScrollGreaterThan20 && (
            <ToTopButton handleScrollToTheTop={handleScrollToTheTop} />
          )}
        </Provider>
      </PersistGate>
    </>
  );
}

export default App;
