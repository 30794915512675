import React, { useEffect, useState, useRef } from "react";
import Logo from "@images/Logo.png";
import "./nav.css";
import { Fonts, useColors } from "@common";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate, useParams } from "react-router-dom";

const listItems = [
  { id: 1, label: "Accueil", path: "/" },
  { id: 2, label: "À propos", path: "/" },
  { id: 3, label: "Secteur d'expertise", path: "/" },
  { id: 4, label: "Nos sujets d'études", path: "/nos_sujets_d'études" },
  { id: 5, label: "Blog", path: "/" },
  { id: 6, label: "Contact", path: "/" },
];

export default ({
  isActive,
  handleOnClickContact,
  handleOnClickSecteur,
  handleOnClickBlog,
  handleClickPropos,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { name } = params;
  const borderRef = useRef(null);
  const Colors = useColors();
  const [clickedElement, setClickedElement] = useState(isActive || 0);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        (isActive !== 0 && window.scrollY > 100) ||
        (isActive === 0 && window.scrollY > 600)
      ) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolling, isActive]);

  const hanleClickItem = (id, path) => {
    setClickedElement(id);
    if (id === 6) {
      handleOnClickContact();
      return;
    } else if (id === 3) {
      handleOnClickSecteur();
    } else if (id === 5) {
      handleOnClickBlog();
    } else if (id === 2) {
      handleClickPropos();
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      navigate(path);
    }
  };

  useEffect(() => {
    if (borderRef.current) {
      borderRef.current.classList.remove("animate");
      const timer = setTimeout(() => {
        borderRef.current.classList.add("animate");
      }, 7);
      return () => clearTimeout(timer);
    }
  }, [clickedElement]);

  useEffect(() => {
    if (name === "blog") {
      setClickedElement(5);
    } else if (name === "secteur") {
      setClickedElement(3);
    } else if (name === "contacts") {
      setClickedElement(6);
    } else if (name === "about") {
      setClickedElement(2);
    }
  }, [name]);

  const handleClickLogo = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="navbar_container"
      style={{
        background: scrolling
          ? "white"
          : "transparent linear-gradient(180deg, #ffffff 0%, #ffffff00 100%) 0% 0% no-repeat padding-box",
        boxShadow: scrolling ? "3px 6px 9px #8787874a" : "none",
      }}
    >
      <div
        className="log__"
        onClick={handleClickLogo}
        style={{ cursor: "pointer" }}
      >
        <img className="navbar__logo" src={Logo} alt="logo" />
      </div>
      <div className="nav_bar_items">
        <ul className="nav-bar-list">
          {listItems.map((ele, index) => (
            <div
              className="the_list_element"
              key={ele.label}
              style={{
                marginTop: ele.id === clickedElement ? "1rem" : "0px",
              }}
            >
              <li
                onClick={() => hanleClickItem(ele.id, ele.path)}
                className="nav_list_item"
                style={{
                  fontFamily: Fonts().primarySemiBold,
                  color: Colors.primary,
                  fontSize: 16
                }}
              >
                {ele.label}
              </li>
              {ele.id === clickedElement && (
                <div className="parent_border_nav">
                  <PlayArrowIcon
                    style={{ color: Colors.secondary, fontSize: 20 }}
                  />
                  <div
                    ref={borderRef}
                    className="nav_list_border"
                    style={{ background: Colors.secondary }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>
      <div className="nav-bar-empty-div"></div>
    </div>
  );
};
