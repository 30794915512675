import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useColors, Fonts } from "@common";
import "./style.css";
import Polygons from "@images/backgrounds/Polygons.png";
import Shapes from "@images/backgrounds/Shapes.png";
import WhatWeDoBest from "./WhatWeDoBest";

export default ({ theRef }) => {
  const Colors = useColors();

  return (
    <div
      className="neo_fast_we_are_the_best"
      style={{ backgroundImage: `url(${Polygons})` }}
      ref={theRef}
    >
      <div className="neo_fast_we_are_the_best_container">
        <div
          className="text_why_we_are_the_best"
          style={{ backgroundImage: `url(${Shapes})` }}
        >
          <h1
            className="title_why_we_are_the_best"
            style={{ color: Colors.primary, fontFamily: Fonts().primaryBold }}
          >
            Recherche et Innovation au service de l'agriculture et de la finance
          </h1>
          <p
            className="paragraph_why_we_are_the_best"
            style={{
              color: Colors.secondary,
              fontFamily: Fonts().primarySemiBold,
              fontWeight: "bold",
            }}
          >
            Des solutions agricoles intelligentes, des technologie avancées en
            finance pour une réussite durable
          </p>
        </div>
        <div className="right_side_about_neo_fast">
          <div className="about_neo_fast_title">
            <p
              className="a_propos_de_neofast"
              style={{
                color: Colors.secondary,
                fontFamily: Fonts().primarySemiBold,
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              {" "}
              À propos de neofast
            </p>
            <PlayArrowIcon
              style={{
                color: Colors.primary,
                transform: "rotate(90deg)",
                fontSize: 19,
                marginLeft: 8,
              }}
            />
          </div>
          <div className="about_neo_fast_descreption">
            <p
              className="neo_fast_descreption"
              style={{
                color: Colors.primary,
                fontFamily: Fonts().primaryMeduim,
              }}
            >
              En tant qu'acteur novateur opérant dans les domaines de la FINTECH
              et de l'AGRITECH, nous sommes spécialisés dans la maîtrise <br />{" "}
              des technologies BlockChain, de l'IA et de l'Internet des objets
              (IoT).
              <br /> Notre expertise unique allie la finance de marché et
              l'agriculture, nous permettant ainsi de proposer des solutions
              novatrices pour
              <br /> relever les défis actuels du secteur agricole. Avec notre
              équipe de chercheurs, d'ingénieurs pluridisciplinaires et
              d'experts en finance
              <br /> de marché, nous sommes en mesure de garantir la qualité de
              notre travail.
              <br /> Grâce à nos services et à nos conseils, vous pouvez obtenir
              un avantage concurrentiel en bénéficiant de notre expertise
              approfondie
              <br /> en agritech et en fintech.
              <br /> Laissez-nous vous aider à prendre une longueur d'avance sur
              vos concurrents.
            </p>
          </div>
        </div>
      </div>
      <WhatWeDoBest />
    </div>
  );
};
