import React from "react";
import "./style.css";
import { useColors, Fonts } from "@common";

export default ({ text, handleOnClick }) => {
  const Colors = useColors();
  return (
    <div className="container" style={{cursor: "pointer"}} onClick={handleOnClick}>
      <div className="p">
        <p
          className="skew_button"
          style={{ fontFamily: Fonts().primarySemiBold }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
