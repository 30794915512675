import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {compose} from 'redux';
import thunk from 'redux-thunk';
import accountReducer from "./account/reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
      // "account",
    ]
  }

const rootReducer = combineReducers({
    account: accountReducer,
  });

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer,composeEnhancer(applyMiddleware(thunk)));
const persistor = persistStore(store)

export { store, persistor };