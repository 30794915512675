import React, { useRef, useState, useEffect } from "react";
import { useColors, Fonts } from "@common";
import { NavBar } from "@components";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import Politiques from "@images/backgrounds/NeofastShapes.png";
import polygonsBack from "@images/backgrounds/polygonsBack.png";
import "./index.css";
import { BiSolidRightArrow } from "react-icons/bi";

const { innerWidth, innerHeight } = window;
export const Items = ({ desc, strong, Ref, useRed }) => {
  const Colors = useColors();
  return (
    <div className="term_section__item">
      <p
        className="term_section__item_desc"
        style={{
          fontSize: "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          className="term_section_Strongs"
          style={{
            fontSize: innerWidth > 414 ? "16px" : "10px",
            fontFamily: Fonts().primarySemiBold,
            color: useRed ? Colors.error : Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {!!strong && !!desc && " : "}
        {desc}
      </p>
    </div>
  );
};

export default ({}) => {
  const Colors = useColors();
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleOnClickContact = () => {
    navigate(`/${"contacts"}`);
  };

  const handleOnClickSecteur = () => {
    navigate(`/${"secteur"}`);
  };
  const handleOnClickBlog = () => {
    navigate(`/${"blog"}`);
  };

  const handleClickPropos = () => {
    navigate(`/${"about"}`);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />

      <div
        className="term_of_use "
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <img
          src={Politiques}
          style={{ position: "absolute", width: 320, top: "5%", right: "5%" }}
        />
        <div className="term_of_us_global second_use">
          <h1
            className="big_title second_use"
            style={{
              fontFamily: Fonts().primaryBold,
              color: Colors.primary,
              marginTop: "8rem",
            }}
          >
            Mentions légales
          </h1>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{
                fontFamily: Fonts().primarySemiBold,
                color: Colors.secondary,
              }}
            >
              Identification
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le site{" "}
              <span
                style={{
                  color: Colors.secondary,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                neofast.io{" "}
              </span>
              , ci-après dénommé le Site, est la propriété de la société
              Neofast, immatriculée avec le numéro SIREN 917551996 R.C.S. Metz
              (ci-après dénommé NEOFAST). <br /> Le responsable de la
              publication du Site est{" "}
              <strong style={{ color: "#000", fontWeight: "bold" }}>
                Gilles RAUCH{" "}
              </strong>{" "}
              en qualité de Président.
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{
                fontFamily: Fonts().primarySemiBold,
                color: Colors.secondary,
              }}
            >
              Responsabilité
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              NEOFAST s’engage à faire ses meilleurs efforts pour assurer aux
              utilisateurs une accessibilité du Site à tout moment. NEOFAST
              prend le plus grand soin pour s’assurer de la qualité et de la
              fiabilité des informations contenues sur le Site.
              <br /> NEOFAST ne peut garantir l’exactitude et la complétude et
              l’actualité des informations diffusées sur son Site, ni la
              permanence de son bon fonctionnement ou sa totale sécurité
              informatique. Les informations contenues dans le Site sont
              fournies à titre indicatif sans aucune garantie, explicite ou
              implicite, de validité, d’exhaustivité ou d’actualité pour la
              seule information générale des utilisateurs, elles ne sauraient
              remplacer ou se substituer à des conseils ou consultations de
              nature juridique, fiscale ou de toute autre nature
              professionnelle.
              <br /> Elle ne pourra être tenue responsable en cas
              d’indisponibilité du site, pour quelque cause que ce soit.
              <br /> Elle ne saurait non plus être tenue responsable des
              erreurs, d’une absence de disponibilité des informations et/ou de
              la présence de virus sur son site.
              <br /> NEOFAST ne sera responsable ni des dommages, temporaires ou
              permanents, causés au système informatique du visiteur ni des
              pertes ou dommages éventuels (y compris, mais sans s’y limiter, la
              perte de données ou de profits) qui seraient subis suite à :{" "}
              <br />
            </p>
            <div
              className="neo-fast-items"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <span style={{ marginTop: 8 }}>
                <BiSolidRightArrow
                  style={{ color: Colors.secondary, fontSize: 12 }}
                />
                {"  "}
                l’accès ou à la navigation sur le Site ;{" "}
              </span>
              <span style={{ marginTop: 8 }}>
                {" "}
                <BiSolidRightArrow
                  style={{ color: Colors.secondary, fontSize: 12 }}
                />{" "}
                {"  "}
                l’exploitation des informations contenues dans le Site ;{" "}
              </span>
              <span style={{ marginTop: 8 }}>
                <BiSolidRightArrow
                  style={{ color: Colors.secondary, fontSize: 12 }}
                />
                {"  "}La copie, la visualisation ou toute autre utilisation du
                Site qui donneraient lieu à une procédure contentieuse ou de
                recouvrement.{" "}
              </span>
            </div>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              NEOFAST ne sera pas non plus responsable des dommages indirects
              résultant d’une intrusion frauduleuse d’un tiers ayant entraîné
              une modification des informations diffusées sur le Site ou un
              préjudice financier ou commercial, perte de programme ou de
              données du système d’information ou autre de l’utilisateur, même
              si NEOFAST a eu connaissance de la possibilité de survenance de
              tels dommages. <br /> L’utilisateur reconnaît avoir pris
              connaissance de la présente notice légale et s’engage à la
              respecter.
              <br />
              L’utilisateur reconnaît également utiliser ces informations sous
              sa seule et unique responsabilité.
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{
                fontFamily: Fonts().primarySemiBold,
                color: Colors.secondary,
              }}
            >
              Propriété Intellectuelle
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              La structure générale du Site, ainsi que chacun des éléments qui
              le compose pris indépendamment, notamment les textes, les données
              et les éléments graphiques, animés ou non, constituent des œuvres
              qui sont protégées en tant que telles par les lois sur la
              propriété intellectuelle, en particulier par le droit d’auteur,
              les dessins et modèles et le droit des marques, et par les traités
              internationaux.
              <br /> La structure générale du Site, ainsi que chacun des
              éléments qui le composent pris indépendamment, notamment les
              textes, les données et les éléments graphiques du Site ne doivent
              en aucun cas être modifiés, reproduits, affichés, présentés,
              distribués ou utilisés à des fins d’intérêt public ou commercial.
              Toute personne qui télécharge ou recueille des informations
              diffusées sur le Site ne dispose sur celles-ci que d’un droit
              d’usage privé, personnel et non transmissible.
              <br /> Les informations recueillies ne sont susceptibles que d’une
              seule reproduction papier ou numérique.
              <br /> Toute utilisation des informations recueillies devra
              mentionner la source.
              <br /> Les œuvres protégées reproduites sur le Site et
              n’appartenant pas à NEOFAST ont fait l’objet d’une autorisation
              expresse et préalable de reproduction de la part du titulaire des
              droits.
              <br /> Toute représentation totale ou partielle du Site ou d’un
              des éléments qui le composent sans l’autorisation expresse
              d'NEOFAST est interdite et constituerait une contrefaçon
              sanctionnée par les articles L.335-2 et suivants du Code de la
              Propriété Intellectuelle.
              <br /> Les bases de données figurant sur le Site sont protégées
              par les articles L.341-1 et suivants du Code de la propriété
              intellectuelle et est sanctionnée toute extraction ou
              réutilisation qualitativement ou quantitativement substantielle du
              contenu des bases de données.
              <br />
              Les marques ou les logotypes figurant sur le Site sont des marques
              déposées par NEOFAST ou par des tiers.
              <br /> Toute reproduction, imitation ou usage, totale ou
              partielle, de ces signes distinctifs sans l’autorisation expresse
              et en violation des interdictions prévues aux articles L.713-2 et
              suivants du Code de propriété intellectuelle engage la
              responsabilité de leur auteur.
              <br /> Les autres signes distinctifs, notamment les dénominations
              sociales, noms commerciaux, enseignes, noms de domaine reproduits
              sur le Site sont la propriété d'NEOFAST ou des tiers et toute
              reproduction sans autorisation expresse est susceptible de
              constituer une usurpation engageant la responsabilité de son
              auteur sur le fondement de l’article 1240 du Code civil modifié
              par l’ordonnance n°2016-131 du 10 février 2016.
              <br /> NEOFAST n’assume aucune obligation de suivi des
              informations que l’utilisateur peut déposer sur le Site.
              <br /> A ce titre, l’utilisateur garantit que n’importe quelle
              information, ou élément (le terme “élément” désigne tout projet,
              fichier ou autres pièces jointes envoyés) ou commentaires autres
              que des données personnelles, que l’utilisateur peut transmettre à
              NEOFAST par le site web de cette dernière, ne viole aucun droit de
              propriété intellectuelle d’un tiers ou une autre loi applicable.
              <br />
              De telles informations, ou éléments ou commentaires, seront
              traités comme non-confidentiels et non-propriétaires.
              <br /> En soumettant ainsi n’importe quelle information, élément
              ou commentaire, l’utilisateur donne à NEOFAST tout droit
              nécessaire, de manière illimité et irrévocable, pour employer,
              exécuter, montrer, modifier et transmettre de telles informations,
              éléments ou commentaires, y compris n’importe quel suggestion,
              concept ou savoir-faire.
              <br /> NEOFAST se réserve le droit d’employer de telles
              informations, de tels éléments ou commentaires de n’importe quelle
              façon qu’elle le choisira.
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{
                fontFamily: Fonts().primarySemiBold,
                color: Colors.secondary,
              }}
            >
              Liens
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Les liens hypertextes mis en place en direction d’autres sites
              sont fournis pour la convenance personnelle des internautes, le
              réseau BLOOM n’exerce aucun contrôle sur ces sites et n’accepte
              aucune responsabilité à leur égard, les pages restant de la seule
              responsabilité de leurs titulaires.
              <br /> La reproduction d’une page du Site dans un cadre
              n’appartenant pas à NEOFAST ou l’insertion d’une page appartenant
              au réseau BLOOM dans la page d’un site n’appartenant pas à NEOFAST
              est interdite.
              <br /> NEOFAST se réserve le droit de supprimer les liens proposés
              par son site vers un site tiers.
              <br />
              <br />
            </p>
          </div>
          <div className="term_of_us_content_1">
            <h1
              className="term_of_us_small_title"
              style={{
                fontFamily: Fonts().primarySemiBold,
                color: Colors.secondary,
              }}
            >
              Loi applicable
            </h1>
            <p
              className="term_of_us_small_desx"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Le présent Site est régi par les lois françaises.
              <br /> L’utilisateur reconnaît avoir pris connaissance de la
              présente notice légale et s’engage à la respecter.
              <br />
              <br />
            </p>
          </div>
        </div>
        <img
          src={polygonsBack}
          style={{
            position: "absolute",
            left: "0%",
            height: "500px",
            width: "100%",
            top: "77%",
            zIndex: -1,
          }}
        />
      </div>

      <Footer
        isIn
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />
    </div>
  );
};
