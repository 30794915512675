// This hook returns the colors object based on the user's prefarred colorScheme (Light / Dark)

const useColors = () => {
  // the Color palette for the whole AP
  return {
    primary: "#2A3B5E",
    secondary: "#FC7032",
  };
};

export default useColors;
