import React from "react";

export default ({ width, height }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: width || 12,
      height: height || 12,
      marginTop: "3px",
    }}
  >
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        backgroundColor: "#F151514D",
      }}
    />
    <div
      style={{
        width: width / 2 || 6,
        height: height / 2 || 6,
        borderRadius: "50%",
        backgroundColor: "#F15151",
      }}
    />
  </div>
);
