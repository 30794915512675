import React, { useState, useEffect } from "react";
import { useColors, Fonts } from "@common";
import { NavBar } from "@components";
import "./index.css";
import OrangeBg from "@images/backgrounds/OrangeBg.png";
import nosSuject from "@images/backgrounds/nosSuject.png";
import Agriculture from "@images/pics/Agriculture.png";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

export default ({}) => {
  // State to track whether images are loaded
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const navigate = useNavigate();
  const Colors = useColors();

  const handleOnClickContact = () => {
    navigate(`/${"contacts"}`);
  };

  const handleOnClickSecteur = () => {
    navigate(`/${"secteur"}`);
  };
  const handleOnClickBlog = () => {
    navigate(`/${"blog"}`);
  };

  const handleClickPropos = () => {
    navigate(`/${"about"}`);
  };

  // useEffect(() => {
  //   const image = new Image();
  //   image.src = nosSuject;
  //   image.onload = () => {
  //     setImagesLoaded(true);
  //   };
  // }, [nosSuject, imagesLoaded]);

  const handleImagesLoad = () => {
    // Set the state to indicate that images are loaded
    setImagesLoaded(true);
  };

  return (
    <div className="nos_suject_container">
      <NavBar
        isActive={4}
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />
      <div
        className="nos_suject_content"
        style={{ backgroundImage: `url(${OrangeBg})` }}
      >
        <div className="nos_sujeect_top_content">
          <h1
            style={{
              fontFamily: Fonts().primaryBold,
              color: Colors.primary,
              fontSize: 28,
            }}
          >
            {" "}
            Sujets d'études
          </h1>
          <p
            style={{
              fontFamily: Fonts().primaryMeduim,
              color: Colors.secondary,
              fontSize: 16,
              textAlign: "center",
            }}
          >
            En repoussant les limites de la technologie, nous proposons des
            solutions novatrices qui transforment <br /> l'agriculture en un
            secteur plus performant et qui amméliore la sécurité et la
            traçabilité financiére
          </p>
        </div>
        <div className="nos_suject_second_content">
          <div className="nos_suject_second_content_pick">
            {/* <img
              src={nosSuject}
              alt="Agriculture"
              className="agriculture"
              onLoad={handleImagesLoad}
            /> */}
            <img src={nosSuject} alt="Agriculture" className="agriculture" onLoad={handleImagesLoad} />
            {!imagesLoaded && <div className="loading-animation" />}

          </div>
          {/* Render "loading" text or element while image is loading */}
          {/* {!imagesLoaded && <div>Loading...</div>} */}
          {/* <div className="nos_suject_second_content_right_side">
            <div className="nos_suject_second_content_left_side_top_right">
              <div className="nos_suject_second_content_left_side_top_border_skew">
                <h1
                  style={{
                    fontFamily: Fonts().primarySemiBold,
                    color: Colors.secondary,
                    fontSize: 22,
                  }}
                >
                  Sécurisation des flux financiers
                </h1>
                <div className="borders_skews03">
                  <div
                    className="border_05"
                    style={{ background: Colors.secondary }}
                  />
                  <div
                    className="border_06"
                    style={{ background: Colors.secondary }}
                  />
                </div>
              </div>
              <p
                style={{
                  fontFamily: Fonts().primaryRegular,
                  color: Colors.primary,
                  fontSize: 14,
                }}
              >
                Des transactions financières fiabilisées,
                <br /> sécurisées et tracées par une solution Blockchain
              </p>
            </div>
            <div className="nos_suject_second_content_left_side_Bottom_right">
              <div className="nos_suject_second_content_left_side_top_border_skew">
                <h1
                  style={{
                    fontFamily: Fonts().primarySemiBold,
                    color: Colors.secondary,
                    fontSize: 22,
                  }}
                >
                  Gestion durable des écosystèmes forestiers
                  <br /> (plans FLEGT)
                </h1>
                <div className="borders_skews04">
                  <div
                    className="border_07"
                    style={{ background: Colors.secondary }}
                  />
                  <div
                    className="border_08"
                    style={{ background: Colors.secondary }}
                  />
                </div>
              </div>
              <p
                style={{
                  fontFamily: Fonts().primaryRegular,
                  color: Colors.primary,
                  fontSize: 14,
                }}
              >
                Développement d'une solution Blockchain
                <br /> pour assurer le suivi des grumes de bois
                <br /> exportées d'Afrique vers l'Europe
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <Footer
        isIn
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
        isNosSuject
      />
    </div>
  );
};
