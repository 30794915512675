import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useColors, Fonts } from "@common";
import Pic1 from "@images/pics/PicPolygons.png";
import Pic2 from "@images/pics/PicPolygons2.png";
import Pic3 from "@images/pics/PicPolygons3.png";
import Polygons from "@images/backgrounds/PolygonsStrok.png";
import "./style.css";

const Pics = [
  { id: 1, image: Pic1 },
  { id: 2, image: Pic2 },
  { id: 3, image: Pic3 },
];

export default ({tTheref}) => {
  const Colors = useColors();

  return (
    <div className="secteur_expertise_neo_fast" ref={tTheref}>
      <div className="secteur_expertise_neo_fast_images">
        {Pics.map((ele, index) => (
          <img
            className="sectuer_boxes_images_elements"
            src={ele.image}
            key={ele.id}
            alt={"image"}
          />
        ))}
      </div>
      <div
        className="next_section_secteur_expertise"
        style={{ backgroundImage: `url(${Polygons})` }}
      >
        <h2
          className="secteur_expertise_title_here"
          style={{ color: Colors.primary, fontFamily: Fonts().primaryBold }}
        >
          Secteurs d’expertise
        </h2>
        <PlayArrowIcon
          style={{
            color: Colors.secondary,
            fontSize: 18,
            transform: "rotate(90deg)",
            position: "relative",
            bottom: "2rem",
          }}
        />
        <div className="sectuer_expertise_desc">
          <p
            className="sectuer_expertise_paragraph"
            style={{
              color: Colors.primary,
              fontFamily: Fonts().primaryMedium,
            }}
          >
            Découvrez une approche révolutionnaire qui fusionne l'innovation
            dans les domaines de l'agriculture et de la finance. Neofast tire
            parti de la puissance de la recherche et du développement pour
            transformer le secteur agricole et ouvrir de nouvelles opportunités
            financières.<br/> Grâce à notre expertise,nous développons des
            technologies de pointe visant à maximiser les rendements, réduire
            les coûts et faciliter l'accès à la sécurisation et à la
            traçabilité des transactions financières.
            <br /> Joignez-vous à nous dès aujourd'hui pour façonner l'avenir de
            l'agriculture et de la finance. Ensemble, <br />
            construisons un avenir prometteur.
          </p>
        </div>
      </div>
    </div>
  );
};
