import React from "react";
import "./index.css";
import { BsPlusSquareDotted } from "react-icons/bs";
import { Fonts } from "@common";

export default ({
  acceptedFiles,
  getRootProps,
  getInputProps,
  error,
  isUpdate,
  picture,
  label,
  isPic,
  styleLabel,
  isLogo,
  isNews,
  isSlider
}) => {
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container_drop">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <strong
          style={{
            color: "#5f5be6",
            fontFamily: Fonts().primarySemiBold,
            fontSize: 12,
            marginTop: 16,
          }}
        >
          {!!label ? label : "Image"}
          {isNews && ' Dimensions 540 x 360'}
          {isSlider && ' Dimensions 3840 x 1800 Et évitez le background blanc dans les sliders'}
          {isLogo &&
            styleLabel === "title_logo_date_description" &&
            " Dimensions 32 x 32"}
          {isPic &&
            (styleLabel === "image_title_text" ||
              styleLabel === "image_title_logo_checklist" ||
              styleLabel === "title_image_text") &&
            " Dimensions 1540 x 720"}
            {isPic && styleLabel === "image_title_subtitle_liste"  && " Dimensions 740 x 500"}
        </strong>
        <p style={{ color: !!error?.message && "red" }}>
          {!!error?.message
            ? error?.message
            : "Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers"}
        </p>
        <BsPlusSquareDotted style={{ fontSize: 33 }} />
      </div>
      <aside>
        <h4>Fichier</h4>
        <ul>{files}</ul>
        {isUpdate && !!picture && (
          <img src={picture} style={{ width: "60px", height: "60px" }} />
        )}
      </aside>
    </section>
  );
};