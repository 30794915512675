import { URL } from "@common";
const axios = require("axios").default;

export const backend = axios.create({
  baseURL: URL, //dev local
  headers: { "Content-Type": "application/json" },
});

export const NOTSECUREBLOGS = {
  NEW: async (data) => {
    return await backend.post("/subscribeds/new", data);
  },
  ALL: async () => {
    return await backend.get("/blogs");
  },
  ONE: async (id) => {
    return await backend.get(`/blogs/${id}`);
  },
};
