import React, { useEffect } from "react";
import { useColors, Fonts } from "@common";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { CiMail, CiMapPin } from "react-icons/ci";
import place from "@images/icons/icons_place.png";
import phone from "@images/icons/phone.png";
import envlop from "@images/icons/envelope.png";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
  AiFillYoutube,
} from "react-icons/ai";

const { innerWidth } = window;
export const Items = ({ text, IconName, isImage }) => {
  return (
    <div className="footer__items">
      {isImage ? (
        <img src={IconName} style={{ width: 16 }} />
      ) : (
        <IconName style={{ color: "#FFF", fontWeight: "bold", fontSize: 14 }} />
      )}

      <h5
        className="footer_item_title"
        style={{
          color: "#fff",
          fontFamily: Fonts().primaryRegular,
          fontSize: "12px",
        }}
      >
        {text}
      </h5>
    </div>
  );
};

export default ({
  isIn,
  handleOnClickContact,
  handleOnClickSecteur,
  handleOnClickBlog,
  handleClickPropos,
  isNosSuject,
}) => {
  const navigate = useNavigate();
  const Colors = useColors();

  useEffect(() => {}, [isIn]);

  const handlePolitique = () => {
    navigate("/politique_de_confidentialites");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleMentions = () => {
    navigate("/politiques");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleclick = (name) => {
    navigate(`/nos_services/${name}`);
  };

  const handleClickNosSuject = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/nos_sujets_d'études");
  };

  return (
    <div
      className="footer"
      style={{ top: isNosSuject ? "7rem" : isIn ? "3rem" : "23rem" }}
    >
      <div className="footer__content">
        <div className="footer__left_content">
          <div className="wrapper">
            <div className="inside">
              <h1
                className="footer__title"
                style={{
                  fontSize: "20px",
                  color: "#FC7032",
                  fontFamily: Fonts().primarySemiBold,
                }}
              >
                à propos de nous
              </h1>
              <p
                className="footer__desc"
                style={{
                  fontSize: "14px",
                  fontFamily: Fonts().primaryRegular,
                  color: "#FFF",
                }}
              >
                En tant qu'acteur novateur opérant dans les domaines de la
                FINTECH et de <br /> l'AGRITECH, nous sommes spécialisés dans la
                maîtrise des technologies BlockChain, <br /> de l'IA et de
                l'Internet des objets (IoT). <br /> Notre expertise unique allie
                la finance de marché et l'agriculture, nous permettant
                <br /> ainsi de proposer des solutions novatrices pour relever
                les défis actuels du secteur <br /> agricole. <br /> Avec notre
                équipe de chercheurs, d'ingénieurs pluridisciplinaires et
                d'experts en
                <br />
                finance de marché, nous sommes en mesure de garantir la qualité
                de notre travail. <br /> Grâce à nos services et à nos conseils,
                vous pouvez obtenir un avantage concurrentiel <br /> en
                bénéficiant de notre expertise approfondie en agritech et en
                fintech. <br /> Laissez-nous vous aider à prendre une longueur
                d'avance sur vos concurrents.
              </p>
            </div>
            <div className="after_about_us">
              <div className="horizontal_border" />

              <div className="footer__items_list">
                {innerWidth > 600 && (
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      onClick={handleClickPropos}
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                    >
                      à propos
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handleOnClickSecteur}
                    >
                      Secteur d'expertice
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handleClickNosSuject}
                    >
                      Nos sujets d'études
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handleOnClickBlog}
                    >
                      Blog
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handleOnClickContact}
                    >
                      Contact
                    </li>
                  </ul>
                )}
              </div>
              <div className="horizontal_border" />
              <div className="footer__items_list">
                {innerWidth > 600 && (
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handleMentions}
                    >
                      Mentions légales
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={handlePolitique}
                    >
                      Politique de confidentialités
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className="footer__right_content">
              {/* {innerWidth <= 600 && (
                <div className="responsive_list_footer">
                  <ul className="footer__list_of_items">
                    <li
                      onClick={handleClickPropos}
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                    >
                      à propos de Neofast
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("digitalisation")}
                    >
                      Secteur d'expertice
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("pilotage")}
                    >
                      Nos solutions innovantes
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("marketing")}
                    >
                      Blog
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FC7032",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => handleclick("externalisation")}
                    >
                      Contact
                    </li>
                  </ul>
                  <div className="horizontal_border vertical_responsive" />
                  <ul className="footer__list_of_items">
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14,
                      }}
                      onClick={() => navigate("/politiques")}
                    >
                      Mentions légales
                    </li>
                    <li
                      className="footer__items_li"
                      style={{
                        color: "#FFF",
                        fontFamily: Fonts().primaryRegular,
                        fontSize: 14
                      }}
                      onClick={() => navigate("/Politiques_confidentialite")}
                    >
                      Politique de confidentialités
                    </li>
                  </ul>
                </div>
              )} */}
              {innerWidth > 600 && (
                <div className="icons__footer">
                  {" "}
                  <Items isImage IconName={phone} text="+33 1 89 76 01 39" />
                  <Items isImage IconName={envlop} text="contact@neo-fast.io" />
                  <Items
                    isImage
                    IconName={place}
                    text="21 Rue Jean Rostand 91400 Orsay, France"
                  />
                </div>
              )}
              {innerWidth <= 600 && (
                <div className="icons__footer">
                  {" "}
                  <Items isImage IconName={phone} text="+33 54 95 78 81 20" />
                  <Items isImage IconName={envlop} text="contact@neo-fast.io" />
                  <Items
                    isImage
                    IconName={place}
                    text="21 Rue Jean Rostand 91400 Orsay, France"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer__foter">
        <div className="copyRight__inside">
          <p
            className="copyRghit"
            style={{
              fontFamily: Fonts().primaryMeduim,
              fontSize: 12,
              color: "#2A3B5E",
            }}
          >
            Copyright 2023 ©
          </p>

          <div className="social_media_footer">
            <AiFillFacebook
              style={{ color: "#2A3B5E", fontWeight: "bold", fontSize: 18 }}
            />
            <AiFillLinkedin
              style={{ color: "#2A3B5E", fontWeight: "bold", fontSize: 18 }}
            />
            <AiFillTwitterSquare
              style={{ color: "#2A3B5E", fontWeight: "bold", fontSize: 18 }}
            />
            <AiFillYoutube
              style={{ color: "#2A3B5E", fontWeight: "bold", fontSize: 18 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
