import { URL } from "@common";
const axios = require("axios").default;

export const backend = axios.create({
  baseURL: URL, //dev local
  headers: { "Content-Type": "application/json" },
});

export const USERS = {
  SignUp: async (data) => {
    return await backend.post("/users/signup", data);
  },
  SignIn: async (data) => {
    return await backend.post("/users/signin", data);
  }
};