import React, { useState, useEffect } from "react";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ isOpen, onClose, children, useFullWidth }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setModalOpen(false);
    onClose();
  };

  return (
    <>
      {modalOpen && (
        <div className="backdrop" onClick={handleClose}>
          <div
            className={`modal ${modalOpen ? "modal-open" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton
              onClick={handleClose}
              aria-label="close"
              style={{ position: "absolute", top: "20px", right: "20px" }}
            >
              <CloseIcon />
            </IconButton>
            <div className="modal-content" style={{width: useFullWidth && "100%"}}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
