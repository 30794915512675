import * as React from "react";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { Fonts } from "@common";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import "./index.css";

const { innerWidth, innerHeight } = window;
const customTheme = (outerTheme, multiline) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#FC7032",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#FC7032",
            "& label.Mui-focused": {
              color: !multiline && "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !multiline && "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor:
                !multiline && "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor:
                !multiline && "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:before, &:after": {
              borderBottom:
                !multiline && "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom:
                !multiline &&
                "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                !multiline &&
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom:
                !multiline && "1px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom:
                !multiline &&
                "1px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                !multiline &&
                "1px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

export default ({
  placeholder,
  required,
  multiline,
  rows,
  className,
  onKeyUp,
  onChange,
  value,
  width,
  error,
}) => {
  const outerTheme = useTheme();

  const asteriskStyle = {
    color: "red",
  };

  return (
    <Box
      sx={{
        display: "grid",

        width: width || "200px",
        position: "relative",
        // gridTemplateColumns: multiline
        //   ? { sm: "1fr 0fr 0fr" }
        //   : { sm: "10fr 0fr 0fr" },
        // gap: 2,
        // width: multiline && "94%" /* multiline ?  "100%" : "94%"*/,
        // margin: "0 auto",
      }}
    >
      <ThemeProvider theme={customTheme(outerTheme, multiline)}>
        <TextField
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          className={className}
          multiline={multiline}
          rows={rows}
          label={
            <span className="text_input_placeholder" style={{fontFamily: Fonts().primaryMedium, color: "#2A3B5E", fontSize: 18, marginLeft: 8}}>
              {placeholder} {required && <span style={asteriskStyle}>*</span>}
            </span>
          }
          variant="standard"
        />
      </ThemeProvider>
      {!!error && (
        <span
          style={{
            color: "red",
            fontFamily: Fonts().primaryRedular,
            fontSize: 12,
            marginTop: 4,
            position: "absolute",
            bottom: "-32px",
          }}
        >
          {error}
        </span>
      )}
    </Box>
  );
};
