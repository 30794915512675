import { backend } from "./axios";

export const BLOGS = {
  NEW: async (data) => {
    return await backend.post("/blogs/new", data);
  },
  ALL: async () => {
    return await backend.get("/blogs");
  },
  ONE: async (id) => {
    return await backend.get(`/blogs/${id}`);
  },
  UPDATE: async (data, id) => {
    return await backend.put(`/blogs/${id}`, data);
  },
  DELETE: async (id) => {
    return await backend.delete(`/blogs/${id}`);
  },
};