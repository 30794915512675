import React, { useEffect, useRef, useState } from "react";
import { NavBar } from "@components";
import "./home.css";
import WeAreTheBest from "../components/WeAreTheBest";
import Sectuers from "../components/SecteurExpetise";
import Blogs from "../components/Blogs";
import Reveal from "@images/videos/Reveal.mp4";
import Contact from "../components/Contact";
import { Footer } from "@screens";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import { SETCOOKIES } from "@redux/account/types";
import { Fonts, useColors } from "@common";
import { Button, Modal } from "@components";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate, useParams } from "react-router-dom";

const { innerHeight, innerWidth } = window;

export default ({}) => {
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const params = useParams();
  const { name } = params;
  const switch1 = useRef(null);
  const switch2 = useRef(null);
  const contactRef = useRef(null);
  const secteurRef = useRef(null);
  const proposRef = useRef(null);
  const blogref = useRef(null);
  const navigate = useNavigate();
  const { cookies } = useSelector(({ account }) => account);
  const Colors = useColors();
  const cookiesref = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [isAnimated, setIsAnimated] = useState(false);
  const [switchValue, setSwitchValue] = useState("désactivé");
  const [personnalisation, setPersonnalisation] = useState(false);
  const [analytiques, setAnalytiques] = useState(false);
  const [isActive, setIsActive] = useState(
    !!cookies?.isCanclled
      ? false
      : !!cookies?.isAccepted
      ? false
      : !!cookies?.preferences
      ? false
      : true
  );

  useEffect(() => {
    setTimeout(() => {
      setIsAnimated(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open-disable-scrolling");
    } else {
      document.body.classList.remove("modal-open-disable-scrolling");
    }

    return () => {
      document.body.classList.remove("modal-open-disable-scrolling");
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (!!name) {
      if (name === "secteur") {
        window.scrollTo({
          top: secteurRef.current.offsetTop + window.innerHeight - 85,
          behavior: "smooth",
        });
      } else if (name === "contacts") {
        window.scrollTo({
          top: contactRef.current.offsetTop + window.innerHeight - 85,
          behavior: "smooth",
        });
      } else if (name === "blog") {
        // window.scrollTo(0, blogref.current.offsetTop);
        window.scrollTo({
          top: blogref.current.offsetTop + window.innerHeight - 85,
          behavior: "smooth",
        });
      } else if (name === "about") {
        window.scrollTo({
          top: proposRef.current.offsetTop + window.innerHeight - 85,
          behavior: "smooth",
        });
      }
    }
  }, [name]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnClickCancell = () => {
    dispatch({
      type: SETCOOKIES,
      payload: { isCanclled: true, isAccepted: false, preferences: null },
    });
    setIsActive(false);
    handleCloseModal();
    toast.error("Les Cookies annulés", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleOnClickAccepte = () => {
    dispatch({
      type: SETCOOKIES,
      payload: { isCanclled: false, isAccepted: true, preferences: null },
    });
    setIsActive(false);
    handleCloseModal();
    toast.success("Les Cookies acceptés", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleOnClickPreferences = () => {
    dispatch({
      type: SETCOOKIES,
      payload: {
        isCanclled: false,
        isAccepted: true,
        preferences: {
          personnalisation: personnalisation,
          analytiques: analytiques,
        },
      },
    });
    setIsActive(false);
    handleCloseModal();
    toast.info("Les préférences enregistrées", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSwitch = (index) => {
    if (index === 1) {
      setPersonnalisation((prv) => !prv);
    } else {
      setAnalytiques((prv) => !prv);
    }
  };

  const handleOnClickContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };
  const handleOnClickSecteur = () => {
    secteurRef.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickBlog = () => {
    blogref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleClickPropos = () => {
    proposRef.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const handleProgress = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const progress = (video.currentTime / video.duration) * 100;
      setVideoProgress(progress);
    }
  };

  return (
    <div>
      <NavBar
        isActive={1}
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />

      <div className="the_animatio_is_here">
        <div className="neo_fast_text">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isVideoLoaded && (
              <div className="loading-progress">
                <progress value={videoProgress} max="100" ></progress>
              </div>
            )}
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              width="100%"
              // height="100%"
              style={{ maxWidth: "100%" }}
              // controls
              onLoadedData={handleVideoLoad}
              onTimeUpdate={handleProgress}
            >
              <source src={Reveal} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <WeAreTheBest theRef={proposRef} />
      <Sectuers tTheref={secteurRef} />
      <Blogs TheRef={blogref} />
      <Contact Theref={contactRef} />
      <Footer
        handleOnClickContact={handleOnClickContact}
        handleOnClickSecteur={handleOnClickSecteur}
        handleOnClickBlog={handleOnClickBlog}
        handleClickPropos={handleClickPropos}
      />
      {isActive && (
        <CSSTransition
          in={isAnimated}
          timeout={1000}
          classNames="bounce"
          unmountOnExit
        >
          <div
            className="section1_cookies"
            ref={cookiesref}
            // style={{
            //   transform: "translateX(-920px)",
            // }}
          >
            <div className="cookies_content">
              <p
                className="cookies_desc"
                style={{
                  fontFamily: Fonts().primaryMeduim,
                  color: Colors.primary,
                }}
              >
                En cliquant sur "Accepter", vous acceptez le stockage de cookies
                sur votre appareil pour améliorer la navigation sur le site,
                analyser l'utilisation du site et nous aider dans nos efforts de
                marketing. Consultez notre politique de confidentialité pour
                plus d'informations.
              </p>
              <div className="cookies_action">
                <a
                  onClick={handleOpenModal}
                  className="first_link_cookies"
                  style={{
                    color: Colors.primary,
                    fontFamily: Fonts().primarySemiBold,
                    fontSize: 14,
                    borderBottom: "1px solid",
                    cursor: "pointer",
                  }}
                >
                  Préférences
                </a>
                <div
                  onClick={handleOnClickCancell}
                  className="skewed-link-container"
                  style={{
                    borderBottom: "1px solid",
                    border: `1px solid ${Colors.primary}`,
                    width: "92px",
                    textAlign: "center",
                    padding: "8px 0px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="skewed-link-text"
                    style={{
                      color: Colors.primary,
                      fontFamily: Fonts().primarySemiBold,
                      fontSize: 14,
                    }}
                  >
                    Refuser
                  </span>
                </div>
                <div
                  onClick={handleOnClickAccepte}
                  className="skewed-link-container"
                  style={{
                    borderBottom: "1px solid",
                    border: `1px solid ${Colors.secondary}`,
                    width: "92px",
                    textAlign: "center",
                    padding: "8px 0px",
                    cursor: "pointer",
                    background: Colors.secondary,
                  }}
                >
                  <span
                    className="skewed-link-text"
                    style={{
                      color: "#FFF",
                      fontFamily: Fonts().primarySemiBold,
                      fontSize: 14,
                    }}
                  >
                    Accepter
                  </span>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-content">
          <div className="modal-top-content">
            <h3
              className="modal__title"
              style={{
                fontFamily: Fonts().primaryBold,
                color: Colors.secondary,
              }}
            >
              Centre de confidentialité
            </h3>
          </div>
          <div className="modal_descreption">
            <p
              className="modal_desc"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Lorsque vous visitez des sites web, ils peuvent stocker ou
              récupérer des données dans votre navigateur. Ce stockage est
              souvent nécessaire pour assurer les fonctionnalités de base du
              site web. Le stockage peut être utilisé à des fins de marketing,
              d'analyse et de personnalisation du site, comme la conservation de
              vos préférences. La vie privée est importante pour nous, donc vous
              avez la possibilité de désactiver certains types de stockage qui
              peuvent ne pas être nécessaires pour le fonctionnement de base du
              site web.Le blocage de certaines catégories peut avoir un impact
              sur votre expérience sur le site web.
            </p>
          </div>
          <div className="modal_actions">
            <div
              onClick={handleOnClickCancell}
              className="skewed-link-container"
              style={{
                borderBottom: "1px solid",
                border: `1px solid ${Colors.primary}`,
                width: "160px",
                textAlign: "center",
                padding: "8px 0px",
                cursor: "pointer",
              }}
            >
              <span
                className="skewed-link-text"
                style={{
                  color: Colors.primary,
                  fontFamily: Fonts().primarySemiBold,
                  fontSize: 14,
                }}
              >
                Refuser les cookies
              </span>
            </div>
            <div
              onClick={handleOnClickAccepte}
              className="skewed-link-container"
              style={{
                borderBottom: "1px solid",
                border: `1px solid ${Colors.secondary}`,
                width: "160px",
                textAlign: "center",
                padding: "8px 0px",
                cursor: "pointer",
                background: Colors.secondary,
              }}
            >
              <span
                className="skewed-link-text"
                style={{
                  color: "#FFF",
                  fontFamily: Fonts().primarySemiBold,
                  fontSize: 14,
                }}
              >
                Autoriser les cookies
              </span>
            </div>
          </div>
          <div className="modal-top-content">
            <h3
              className="modal__title"
              style={{
                fontFamily: Fonts().primaryBold,
                color: Colors.secondary,
              }}
            >
              Gérer les préférences.
            </h3>
          </div>
          <div className="modal_essencial">
            <p
              className="essencial"
              style={{ fontFamily: Fonts().primaryRegular, fontSize: 12 }}
            >
              Essentiels
            </p>
            <p
              className="Toujours_actifs"
              style={{ fontFamily: Fonts().primaryRegular, fontSize: 12 }}
            >
              Toujours actifs
            </p>
          </div>
          <div className="small_des">
            <p
              className="modal_desc"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              Ces éléments sont nécessaires pour permettre la fonctionnalité de
              base du site web.
            </p>
          </div>
          <div className="modal_border_second" />
          <div className="after_second_border">
            <div className="neo_fast_analytiques">
              <h4
                className="after_second_border_title"
                style={{ fontFamily: Fonts().primarySemiBold }}
              >
                Personnalisation
              </h4>
              <div
                className="switch_neofast"
                style={{
                  cursor: "pointer",
                  background: !personnalisation ? "#a6a2a275" : "#ff6927ab",
                }}
                onClick={() => handleSwitch(1)}
              >
                <PlayArrowIcon
                  ref={switch1}
                  style={{
                    color: !personnalisation
                      ? Colors.primary
                      : Colors.secondary,
                    position: "absolute",
                    left: personnalisation ? "55px" : -15,
                    fontSize: 45,
                    transition: "all .4s ease-in-out",
                    animation: personnalisation
                      ? "bounceswitch 1s ease-in-out"
                      : "none",
                  }}
                />
                <p
                  className="switch_label"
                  style={{
                    fontFamily: Fonts().primaryRegular,
                    fontSize: 12,
                    transition: "all .4s ease-in-out",
                    marginLeft: !personnalisation ? 12 : -18,
                    color: !personnalisation ? Colors.primary : "#FFF",
                  }}
                >
                  {!personnalisation ? switchValue : "activé"}
                </p>
              </div>
            </div>

            <div className="modal_desc_with_check_box">
              <p
                className="modal_desc seconds"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Ces éléments permettent au site web de se souvenir des choix que
                vous faites (comme votre nom d'utilisateur, votre langue ou la
                région dans laquelle vous vous trouvez) et de fournir des
                fonctionnalités améliorées et plus personnelles.
                <br /> Par exemple, un site web peut vous fournir des rapports
                météorologiques locaux ou des informations sur le trafic en
                stockant des données sur votre emplacement actuel.
              </p>
              {/* <Checkbox
                {...label}
                checked={personnalisation}
                onChange={handlePersonalisation}
              /> */}
            </div>
          </div>
          <div className="modal_border_second" />
          <div className="after_second_border">
            <div className="neo_fast_analytiques">
              <h4
                className="after_second_border_title"
                style={{ fontFamily: Fonts().primarySemiBold }}
              >
                Analytiques
              </h4>
              <div
                className="switch_neofast"
                style={{
                  cursor: "pointer",
                  background: !analytiques ? "#a6a2a275" : "#ff6927ab",
                }}
                onClick={() => handleSwitch(2)}
              >
                <PlayArrowIcon
                  ref={switch2}
                  style={{
                    color: !analytiques ? Colors.primary : Colors.secondary,
                    position: "absolute",
                    left: analytiques ? "55px" : -15,
                    fontSize: 45,
                    transition: "all .4s ease-in-out",
                    animation: analytiques
                      ? "bounceswitch 1s ease-in-out"
                      : "none",
                  }}
                />
                <p
                  className="switch_label"
                  style={{
                    fontFamily: Fonts().primaryRegular,
                    fontSize: 12,
                    transition: "all .4s ease-in-out",
                    marginLeft: !analytiques ? 12 : -18,
                    color: !analytiques ? Colors.primary : "#FFF",
                  }}
                >
                  {!analytiques ? switchValue : "activé"}
                </p>
              </div>
            </div>

            <div className="modal_desc_with_check_box">
              <p
                className="modal_desc second"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                Ces éléments aident l'opérateur du site web à comprendre comment
                fonctionne son site web, comment les visiteurs interagissent
                avec le site et s'il peut y avoir des problèmes techniques.
                <br /> Ce type de stockage ne collecte généralement pas
                d'informations permettant d'identifier un visiteur.
              </p>
            </div>
          </div>

          <div className="modal_footer">
            <div
              onClick={handleOnClickPreferences}
              className="skewed-link-container"
              style={{
                borderBottom: "1px solid",
                border: `1px solid ${Colors.secondary}`,
                width: "300px",
                textAlign: "center",
                padding: "8px 0px",
                cursor: "pointer",
                background: Colors.secondary,
              }}
            >
              <span
                className="skewed-link-text"
                style={{
                  color: "#FFF",
                  fontFamily: Fonts().primarySemiBold,
                  fontSize: 14,
                }}
              >
                Confirmer mes préférences et fermer
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
