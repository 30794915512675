import React, { useState, useRef, useEffect } from "react";
import NeofastShapes from "@images/backgrounds/NeofastShapes.png";
import { useColors, Fonts } from "@common";
import { TextInput, Select, NativeSelect } from "@components";
import { CONTACTS2 } from "@services";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";

const DATA = [
  { id: 1, name: "Agriculture" },
  { id: 2, name: "Finance" },
  { id: 3, name: `Recherche et développement` },
  { id: 4, name: "Ingénierie" },
  { id: 5, name: "Autre" },
  { id: 0, name: "Aucun" },
];
export default ({ Theref }) => {
  const selectRef = useRef(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [tele, setTele] = useState("");
  const [seciete, setSociete] = useState("");
  const [message, setMessage] = useState("");
  const [subjectOfText, setSubjectOfText] = useState("");
  const [secteur, setSecteur] = useState(null);
  const Colors = useColors();
  const [isLoading, setIsLoaing] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const hanleOnClick = () => {
    setOpenSelect((prv) => !prv);
  };

  useEffect(() => {
    // Function to close the select when clicking outside of it
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpenSelect(false); // Call the click handler to close the select
      }
    };

    // Attach the click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hanleOnClick]);

  const handleUserName = (e) => {
    setUsername(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleTele = (e) => {
    setTele(e.target.value);
  };
  const handleSocity = (e) => {
    setSociete(e.target.value);
  };
  const handleSubjectOfText = (e) => {
    setSubjectOfText(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyUp = (event) => {
    // Remove any non-numeric characters from the input value
    const sanitizedValue = event.target.value.replace(/\D/g, "");

    // Update the input value with the sanitized numeric value
    event.target.value = sanitizedValue;
  };

  const handleOnChangeSecteur = (e) => {
    if (e === "Aucun") {
      setSecteur(null);
      setOpenSelect(false);
      return;
    }
    setSecteur(e);
    setOpenSelect(false);
  };

  const handleChange = () => {
    setIsLoaing(true);
    if (username && email && message && subjectOfText) {
      const params = {
        username: username,
        email: email,
        phone: tele,
        society: seciete,
        message: message,
        from: "home",
        subject: subjectOfText,
        secteur: secteur,
      };
      CONTACTS2.NEW(params)
        .then((data) => {
          setIsLoaing(false);
          if (data.status === 201 || data.status === 200) {
            setEmail("");
            setUsername("");
            setMessage("");
            setTele("");
            setSociete("");
            setSubjectOfText("");
            setSecteur("");
            toast.success("Les informations personnelles enregistrées", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          setIsLoaing(false);
          if (error) {
            setTimeout(() => {
              setEmail("");
              setUsername("");
              setMessage("");
              setTele("");
              setSociete("");
              toast.error("Quelque chose s'est mal passé", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }, 100);
          }
        });
    } else {
      setIsLoaing(false);
      setTimeout(() => {
        setEmail("");
        setUsername("");
        setMessage("");
        setTele("");
        setSociete("");
        toast.error("Vous devez d'abord remplir les champs", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }, 100);
    }
  };

  return (
    <div className="contact_neofast_container" ref={Theref}>
      <img src={NeofastShapes} className="neo_fast_contact_image" />
      <div className="contact_form">
        <div className="form_neo_fast">
          <h1
            className="contact_neo_fast_title"
            style={{ color: Colors.primary, fontFamily: Fonts().primaryBold }}
          >
            Contact
          </h1>
          <div className="fields_neo_fast_form">
            <div className="first_Section_form">
              <TextInput
                value={username}
                placeholder="Nom et Prénom"
                required
                onChange={handleUserName}
              />
              <TextInput
                placeholder="Email"
                required
                onChange={handleEmail}
                value={email}
              />
              <TextInput
                placeholder="Téléphone"
                onKeyUp={handleKeyUp}
                onChange={handleTele}
                value={tele}
              />
            </div>
            <div className="second_Section_form">
              <TextInput
                placeholder="Société"
                onChange={handleSocity}
                value={seciete}
              />
              <div className="neo_fast_form_select">
                {/* <Select
                  Label={"Secteurs d’activité"}
                  width="200px"
                  value={secteur}
                  handleOnChange={handleOnChangeSecteur}
                  data={DATA}
                /> */}

                <NativeSelect
                  defaultVal="Secteurs d’activité"
                  data={DATA}
                  value={secteur}
                  handleSelectValue={handleOnChangeSecteur}
                  openSelect={openSelect}
                  hanleOnClick={hanleOnClick}
                  selectRef={selectRef}
                  width="230px"
                />
              </div>
              <TextInput
                placeholder="Sujet de message"
                required
                onChange={handleSubjectOfText}
                value={subjectOfText}
              />
            </div>

            <div className="second_Section_form">
              <TextInput
                value={message}
                onChange={handleMessage}
                placeholder="Saissez votre message ici..."
                required
                multiline
                rows={8}
                className="message_neofast"
                width="100%"
              />
            </div>

            <div className="this_is_the_actions">
              <div
                onClick={handleChange}
                className="skewed-link-container"
                style={{
                  borderBottom: "1px solid",
                  border: `1px solid ${Colors.secondary}`,
                  width: "170px",
                  textAlign: "center",
                  padding: "13px 5px",
                  cursor: "pointer",
                  background: Colors.secondary,
                  position: "relative",
                }}
              >
                <span
                  className="skewed-link-text"
                  style={{
                    color: "#FFF",
                    fontFamily: Fonts().primarySemiBold,
                    fontSize: 20,
                  }}
                >
                  Envoyer
                </span>
                {isLoading && (
                  <Box
                    sx={{ display: "flex" }}
                    style={{
                      fontSize: 100,
                      color: Colors.primary,
                      position: "absolute",
                      right: 20,
                      top: "14px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
