import React, { useState, useEffect, useRef } from "react";
import { useColors, Fonts } from "@common";
import { Parallelogram, Select, SearchInput, NativeSelect } from "@components";
import "./style.css";
import plexusShapesFloatingParticles from "@images/backgrounds/plexusShapesFloatingParticles.png";
import BlogePic from "@images/pics/PicPolygons.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";
import { BLOGS, NOTSECUREBLOGS } from "@services";

const URL =
  document.location.protocol +
  "//" +
  // document.location.hostname +
  "neofast.io/app" +
  "/images/blogs/";

const DATA = [
  { id: 0, name: "Aucun" },
  { id: 1, name: "2023" },
];

const SECTEURS = [
  { id: 0, name: "Aucun" },
  { id: 1, name: "Agriculture" },
  { id: 2, name: "Ingénierie" },
  { id: 3, name: "Recherche & dév..." },
  { id: 4, name: "Finance" },
];

export default ({ TheRef }) => {
  const selectRef = useRef(null);
  const selectRefs = useRef(null);
  const Colors = useColors();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoaing] = useState(false);
  const [date, setDate] = useState(null);
  const [secteur, setSecteur] = useState(null);

  const [openSelect, setOpenSelect] = useState(false);
  const [openSelectS, setOpenSelectS] = useState(false);

  const hanleOnClick = () => {
    setOpenSelect((prv) => !prv);
  };
  const hanleOnClickS = () => {
    setOpenSelectS((prv) => !prv);
  };

  const handleOnChange = (e) => {
    if (e === "Aucun") {
      setDate(null);
      setOpenSelect(false);
      return;
    }
    setDate(e);
    setOpenSelect(false);
  };
  const handleOnChangeSecteur = (e) => {
    if (e === "Aucun") {
      setSecteur(null);
      setOpenSelectS(false);
      return;
    }
    setSecteur(e);
    setOpenSelectS(false);
  };

  useEffect(() => {
    // Function to close the select when clicking outside of it
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpenSelect(false); // Call the click handler to close the select
      }
      if (selectRefs.current && !selectRefs.current.contains(event.target)) {
        setOpenSelectS(false);
      }
    };

    // Attach the click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hanleOnClick, openSelectS]);

  const displayOnce = useRef(true);

  useEffect(() => {
    if (displayOnce.current) {
      fetchBlogs();
      displayOnce.current = false;
    }
  }, []);

  const fetchBlogs = () => {
    NOTSECUREBLOGS.ALL()
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          setBlogs(result.data.data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error, "@************@ from fetch data");
        }
      });
  };

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/newsletter_registration_form");
  };

  return (
    <div
      className="neo_fast_blogs"
      style={{ backgroundImage: `url(${plexusShapesFloatingParticles})` }}
      ref={TheRef}
    >
      <div className="neo_fast_blogs_container">
        <h1
          className="neo_fast_container_title"
          style={{ color: Colors.primary }}
        >
          Blog
        </h1>
        <Parallelogram
          text="Inscrivez-vous pour recevoir nos actualités"
          handleOnClick={handleOnClick}
        />
        <div className="neo_fast_blogs_filter_form">
          <NativeSelect
            defaultVal="Secteurs d’activité"
            data={SECTEURS}
            value={secteur}
            handleSelectValue={handleOnChangeSecteur}
            openSelect={openSelectS}
            hanleOnClick={hanleOnClickS}
            selectRef={selectRefs}
            width="250px"
          />
          <NativeSelect
            defaultVal="Années"
            data={DATA}
            value={date}
            handleSelectValue={handleOnChange}
            openSelect={openSelect}
            hanleOnClick={hanleOnClick}
            selectRef={selectRef}
            width="160px"
          />
          <SearchInput />
        </div>
        <div className="items__noeofast">
          {blogs.map((ele, index) => (
            <div className="mapped_item__container" key={ele.id}>
              <img src={URL + ele.picture} className="mapped_item_image" />
              <h2
                className="mapped_item_title"
                style={{
                  color: Colors.secondary,
                  fontFamily: Fonts().primarySemiBold,
                }}
              >
                {ele.title}
              </h2>
              <p
                className="mapped_item_desc"
                style={{
                  color: Colors.primary,
                  fontFamily: Fonts().primaryMedium,
                }}
              >
                {ele.desc}
              </p>
              <div className="se_more__btn">
                <div
                  className="se_more_btn_content"
                  style={{
                    fontFamily: Fonts().primaryMedium,
                    color: Colors.secondary,
                  }}
                >
                  En savoir plus
                </div>
                <PlayArrowIcon
                  style={{ color: Colors.secondary, fontSize: 12 }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
