import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { BiSolidDownArrow } from "react-icons/bi";
import { Fonts } from "@common";

export default ({
  data,
  value,
  defaultVal,
  handleSelectValue,
  openSelect,
  hanleOnClick,
  selectRef,
  width
}) => {
  return (
    <div className="select_drop_down" ref={selectRef} style={{width: width || 200}}>
      <div className="fixed_content">
        <div className="items_default">
          <h4
            className="default_title"
            onClick={hanleOnClick}
            style={{
              fontFamily: Fonts().primaryMedium,
              fontSize: 17,
              color: "#2A3B5E",
            }}
          >
            {!value ? defaultVal : value === 'Recherche et développement' ? 'Recherche et dév..': value}
          </h4>
          <div className={`child_items ${openSelect ? "open_select" : ""}`}>
            {data.map((ele, index) => (
              <h5
                onClick={() => handleSelectValue(ele.name)}
                className="items_childs"
                key={ele.name}
                style={{
                  fontFamily: Fonts().primaryRegular,
                  fontSize: 17,
                  color: "#2A3B5E",
                  lineHeight: ele.name === 'Recherche et développement' && 1.5,
                }}
              >
                {ele.name === "Recherche et développement" ? `Recherche et \n développement` : ele.name}
              </h5>
            ))}
          </div>
        </div>

        <BiSolidDownArrow
          onClick={hanleOnClick}
          style={{
            color: "#FC7032",
            fontSize: 15,
            transition: "all .3s ease-in-out",
            transform: openSelect ? "rotate(180deg)" : "rotate(0deg)",
            marginTop: 15,
            marginLeft: 14,
          }}
        />
      </div>
    </div>
  );
};
