import React from "react";
import "./Button.css";
import Top from "@images/icons/top.png";

const Button = ({ handleScrollToTheTop }) => {
  return (
    <div className="to_top_button" onClick={handleScrollToTheTop}>
      <img src={Top} alt="top" />
      {/* <div className="main-triangle" onClick={handleScrollToTheTop}> */}
      {/* <div className="triangle3">
        <div className="border_to_top">
          <div className="to_top_1" />
          <div className="to_to_2" />
        </div>
        <div className="triangle3Text">to top</div>
      </div> */}
    </div>
  );
};

export default Button;
