import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./style.css";
import { useColors, Fonts } from "@common";
import Pic1 from "@images/boxies/Picture1.png";
import Pic2 from "@images/boxies/Picture2.png";
import Pic3 from "@images/boxies/Picture3.png";
import Boxes from "./Boxes";

const BoxesData = [
  {
    id: 1,
    pic: Pic1,
    title: "Agritech",
    desc: "Au service de l'innovation et de la durabilité Agricole",
  },
  {
    id: 2,
    pic: Pic2,
    title: "Blockchain",
    desc: "Pour répondre aux enjeux de traçabilité et sécurisation des données",
  },
  {
    id: 3,
    pic: Pic3,
    title: "Fintech",
    desc: "Des avancées technologiques et numériques appliquées aux secteurs bancaire et financier.",
  },
];

export default ({}) => {
  const Colors = useColors();
  return (
    <div className="what_we_do_best_neo_fast">
      <div className="what_we_do_best_conatiner">
        <h2
          className="what_we_do_best_text"
          style={{
            color: Colors.primary,
            fontFamily: Fonts().primaryBold,
            fontSize: "36px",
          }}
        >
          Nos expertises
        </h2>
        <div className="what_we_do_best_border">
          <PlayArrowIcon style={{ color: Colors.secondary, fontSize: 22 }} />
          <div className="what_we_do_best_border_icon" />
        </div>
      </div>
      <div className="what_we_do_best_boxes">
        {BoxesData.map((ele, index) => (
          <Boxes data={ele} key={ele.id} index = {index} />
        ))}
      </div>
    </div>
  );
};
